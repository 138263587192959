import React, { useState, useEffect } from "react";
import { X } from "lucide-react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Input from "../../components/Input/Input";
import Button from "../../components/Button/Button";

const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
  <div className="relative w-full" ref={ref}>
    <input
      value={value}
      onClick={onClick}
      readOnly
      className={`w-full px-3.5 py-2.5 rounded-lg border border-gray-300 bg-white text-gray-700 text-base focus:outline-none focus:border-gray-400 shadow-xs cursor-pointer`}
      placeholder="Select date range"
    />
  </div>
));

const GameFilter = ({ isOpen, onClose, onApply, initialFilters, rooms }) => {
  const [mounted, setMounted] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const [dateRange, setDateRange] = useState(
    initialFilters?.dateRange || "all"
  );
  const [dateValue, setDateValue] = useState({
    startDate: initialFilters?.startDate
      ? new Date(initialFilters.startDate)
      : null,
    endDate: initialFilters?.endDate ? new Date(initialFilters.endDate) : null,
  });
  const [gameType, setGameType] = useState(initialFilters?.gameType || "all");
  const [location, setLocation] = useState(initialFilters?.location || "all");
  const [sortBy, setSortBy] = useState(initialFilters?.sortBy || "date_newest");

  useEffect(() => {
    if (isOpen) {
      setMounted(true);
      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          setIsAnimating(true);
        });
      });
    } else {
      setIsAnimating(false);
      const timer = setTimeout(() => setMounted(false), 300);
      return () => clearTimeout(timer);
    }
  }, [isOpen]);

  useEffect(() => {
    setDateRange(initialFilters?.dateRange || "all");
    setDateValue({
      startDate: initialFilters?.startDate
        ? new Date(initialFilters.startDate)
        : null,
      endDate: initialFilters?.endDate
        ? new Date(initialFilters.endDate)
        : null,
    });
    setGameType(initialFilters?.gameType || "all");
    setLocation(initialFilters?.location || "all");
    setSortBy(initialFilters?.sortBy || "date_newest");
  }, [initialFilters]);

  const dateRangeOptions = [
    { value: "all", label: "All Time" },
    { value: "today", label: "Today" },
    { value: "week", label: "Last 7 Days" },
    { value: "month", label: "Last 30 Days" },
    { value: "custom", label: "Custom Range" },
  ];

  const gameTypeOptions = [
    { value: "all", label: "All Games" },
    { value: "NLHE", label: "Texas Hold'em" },
    { value: "PLO", label: "Omaha" },
  ];

  const locationOptions = [
    { value: "all", label: "All Locations" },
    ...rooms?.map((room) => ({ value: room?.room_name, label: room?.room_name })),
  ];

  const sortByOptions = [
    { value: "date_newest", label: "Date (Newest First)" },
    { value: "date_oldest", label: "Date (Oldest First)" },
    { value: "pnl_highest", label: "P&L (Highest)" },
    { value: "pnl_lowest", label: "P&L (Lowest)" },
    { value: "duration_longest", label: "Duration (Longest)" },
    { value: "duration_shortest", label: "Duration (Shortest)" },
  ];

  const handleReset = () => {
    setDateRange("all");
    setDateValue({
      startDate: null,
      endDate: null,
    });
    setGameType("all");
    setLocation("all");
    setSortBy("date_newest");
  };

  const handleApply = () => {
    onApply({
      dateRange,
      startDate: dateValue.startDate
        ? new Date(dateValue.startDate)?.toLocaleDateString("en-CA")
        : null,
      endDate: dateValue.endDate
        ? new Date(dateValue.endDate)?.toLocaleDateString("en-CA")
        : null,
      gameType,
      location,
      sortBy,
      roomId: initialFilters?.roomId || null,
    });
    onClose();
  };

  const handleDateRangeChange = (e) => {
    const value = e.target.value;
    setDateRange(value);
    const today = new Date();
    let start = null;
    let end = null;

    switch (value) {
      case "today":
        start = end = today;
        break;
      case "week":
        end = today;
        start = new Date(today);
        start.setDate(start.getDate() - 7);
        break;
      case "month":
        end = today;
        start = new Date(today);
        start.setDate(start.getDate() - 30);
        break;
      case "custom":
        setDateValue({
          startDate: initialFilters?.startDate
            ? new Date(initialFilters.startDate)
            : null,
          endDate: initialFilters?.endDate
            ? new Date(initialFilters.endDate)
            : null,
        });
        return;
      default:
        start = null;
        end = null;
    }

    setDateValue({ startDate: start, endDate: end });
  };

  const renderCustomDateRange = () => {
    if (dateRange === "all") return null;

    return (
      <div className="mt-4">
        <div className="space-y-1.5 relative">
          <label className="text-sm font-medium text-gray-700">
            Select Dates
          </label>
          <DatePicker
            disabled={dateRange !== "custom"}
            selectsRange={true}
            startDate={dateValue.startDate}
            endDate={dateValue.endDate}
            onChange={(update) => {
              setDateValue({
                startDate: update[0],
                endDate: update[1],
              });
            }}
            customInput={<CustomInput />}
            maxDate={new Date()}
            dateFormat="MMM dd, yyyy"
            monthsShown={1}
            calendarClassName="shadow-lg border-gray-200"
            popperClassName="date-picker-popper"
          />
        </div>
      </div>
    );
  };

  if (!mounted) return null;

  return (
    <div
      className={`fixed inset-0 z-50 transition-opacity duration-300 ${
        isAnimating ? "opacity-100" : "opacity-0"
      }`}
      aria-modal="true"
      role="dialog"
    >
      <div
        className={`absolute inset-0 bg-black transition-opacity duration-300 ${
          isAnimating ? "bg-opacity-50" : "bg-opacity-0"
        }`}
        onClick={onClose}
      />

      <div
        className={`absolute bottom-0 left-0 right-0 bg-white rounded-t-xl transform transition-transform duration-300 ease-out ${
          isAnimating ? "translate-y-0" : "translate-y-full"
        }`}
      >
        <div className="p-4 border-b max-h-[90vh] overflow-y-auto">
          <div className="flex items-center justify-between mb-4">
            <h2 className="text-xl font-bold">Filters</h2>
            <button
              onClick={onClose}
              className="text-gray-600 hover:text-gray-800 transition-colors"
            >
              <X size={24} />
            </button>
          </div>

          <div className="mb-4 relative">
            <Input
              label="Date Range"
              type="select"
              value={dateRange}
              onChange={handleDateRangeChange}
              options={dateRangeOptions}
              placeholder="Select date range"
            />
            {renderCustomDateRange()}
          </div>

          <div className="mb-4">
            <Input
              label="Game Type"
              type="select"
              value={gameType}
              onChange={(e) => setGameType(e.target.value)}
              options={gameTypeOptions}
              placeholder="Select game type"
            />
          </div>

          <div className="mb-4">
            <Input
              label="Location"
              type="select"
              value={location}
              onChange={(e) => setLocation(e.target.value)}
              options={locationOptions}
              placeholder="Select location"
            />
          </div>

          <div className="mb-4">
            <Input
              label="Sort By"
              type="select"
              value={sortBy}
              onChange={(e) => setSortBy(e.target.value)}
              options={sortByOptions}
              placeholder="Select sorting option"
            />
          </div>

          <div className="grid grid-cols-2 gap-2">
            <Button onClick={handleReset} variant="outline">
              Reset
            </Button>
            <Button onClick={handleApply} variant="primary">
              Apply Filters
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GameFilter;
