import React, { useEffect, useState } from "react";
import { X, History } from "lucide-react";
import axios from "axios";
import Table from "../../components/Table/Table";
import { API } from "../../services";
import { formatDateTimeShort } from "../../utilities/commonFunction";

const ViewHistoryModal = ({
  isOpen,
  onClose,
  gameId,
  playerId,
  playerName,
}) => {
  const [history, setHistory] = useState([]);
  const [error, setError] = useState([]);
  const [loading, setLoading] = useState([]);
  const columns =
    gameId && playerId
      ? [
          {
            key: "timestamp",
            header: "TIME",
            render: (row) => (
              <span className="text-sm">
                {formatDateTimeShort(row?.timestamp)}
              </span>
            ),
          },

          {
            key: "activity",
            header: "ACTIVITIES",
            render: (row) => <span className="text-sm">{row?.activity}</span>,
          },
        ]
      : [
          {
            key: "timestamp",
            header: "TIME",
            render: (row) => (
              <span className="text-sm">
                {formatDateTimeShort(row?.timestamp)}
              </span>
            ),
          },
          {
            key: "player",
            header: "PLAYER",
            render: (row) => <span className="text-sm">{row?.player}</span>,
          },
          {
            key: "activity",
            header: "ACTIVITIES",
            render: (row) => <span className="text-sm">{row?.activity}</span>,
          },
        ];
  useEffect(() => {
    const fetchRooms = async () => {
      try {
        setLoading(true);
        if (gameId && playerId) {
          const {
            data: { data },
          } = await API.getPlayerHistory(
            gameId,
            playerId,
            localStorage.getItem("token")
          );
          setHistory(data);
        } else {
          const {
            data: { data },
          } = await API.getGameHistory(gameId, localStorage.getItem("token"));
          setHistory(data);
        }
      } catch (err) {
        setError("Failed to fetch rooms. Please try again later.");
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };
    if (isOpen) fetchRooms();
    else setHistory(null);
  }, [isOpen]);
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg shadow-lg w-full max-w-md">
        <div className="p-4">
          <div className="flex justify-between items-center mb-4">
            <div className="p-3 rounded-lg border border-[#E4E7EC] bg-white shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)]">
              <History size={24} />
            </div>
            <button
              onClick={onClose}
              className="text-gray-400 hover:text-gray-600 transition-colors p-2"
              aria-label="Close modal"
            >
              <X size={24} />
            </button>
          </div>

          <h2 className="text-xl font-bold mb-4">
            {gameId && playerId
              ? `Game history for ${playerName}`
              : "View game history"}
          </h2>

          <div className="overflow-y-auto max-h-96">
            <Table columns={columns} data={history} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(ViewHistoryModal);
