import React, { useState, createContext, useContext } from "react";
import Snackbar from "../components/Snackbar/Snackbar";

const SnackbarContext = createContext(null);

export const useSnackbar = () => {
  const context = useContext(SnackbarContext);
  if (!context) {
    throw new Error("useSnackbar must be used within a SnackbarProvider");
  }
  return context;
};

export const SnackbarProvider = ({ children }) => {
  const [snacks, setSnacks] = useState([]);

  const showSnackbar = (message, type = "success", duration = 4000) => {
    const id = Date.now();
    setSnacks((prev) => [...prev, { id, message, type }]);

    setTimeout(() => {
      setSnacks((prev) => prev.filter((snack) => snack.id !== id));
    }, duration);
  };

  const removeSnack = (id) => {
    setSnacks((prev) => prev.filter((snack) => snack.id !== id));
  };

  return (
    <SnackbarContext.Provider value={{ showSnackbar }}>
      {children}
      <div className="fixed top-4 left-1/2 -translate-x-1/2 z-50 flex flex-col gap-2 pointer-events-none w-full max-w-[90vw] items-center">
        {snacks.map((snack) => (
          <Snackbar
            key={snack.id}
            message={snack.message}
            type={snack.type}
            onClose={() => removeSnack(snack.id)}
          />
        ))}
      </div>
    </SnackbarContext.Provider>
  );
};
