import React from "react";

const Toggle = ({ toggleOn, toggleSwitch }) => {
  return (
    <div
      className={`flex w-11 h-6 p-0.5 items-center rounded-full cursor-pointer transition-colors duration-300 ease-in-out ${
        toggleOn ? "bg-[#191D23] justify-end" : "bg-[#e5e7eb] justify-start"
      }`}
      onClick={toggleSwitch}
    >
      <div className="w-4 h-4 bg-white rounded-full shadow-md transition-transform duration-300 ease-in-out" />
    </div>
  );
};

export default Toggle;
