import React, { useState, useRef, useEffect } from "react";
import Input from "../Input/Input";
import { API } from "../../services";

const AutocompleteInput = ({
  value,
  onChange,
  disabled,
  placeholder = "Username",
  errorMessage,
  className,
  name,
}) => {
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [loading, setLoading] = useState(false);
  const wrapperRef = useRef(null);
  const debounceTimeout = useRef(null);

  const fetchSuggestions = async (searchText) => {
    if (!searchText) {
      setSuggestions([]);
      return;
    }

    try {
      setLoading(true);
      const response = await API.searchRoomUsers(
        searchText,
        localStorage.getItem("token")
      );
      setSuggestions(response.data.data.users || []);
    } catch (error) {
      console.error("Error fetching suggestions:", error);
      setSuggestions([]);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const newValue = e.target.value.toLowerCase();
    onChange({
      target: {
        name: name || "username",
        value: newValue,
      },
    });

    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    if (newValue.trim()) {
      debounceTimeout.current = setTimeout(() => {
        fetchSuggestions(newValue.trim());
      }, 300);
    } else {
      setSuggestions([]);
    }

    setShowSuggestions(true);
  };

  const handleSuggestionClick = (suggestion) => {
    onChange({
      target: {
        name: name || "username",
        value: suggestion.username.toLowerCase(),
      },
    });
    setShowSuggestions(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShowSuggestions(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
    };
  }, []);

  return (
    <div ref={wrapperRef} className="relative w-full">
      <Input
        type="text"
        placeholder={placeholder}
        value={value || ""}
        onChange={handleInputChange}
        disabled={disabled}
        errorMessage={errorMessage}
        className={className}
        name={name}
      />

      {showSuggestions && suggestions.length > 0 && (
        <div className="absolute z-50 w-full mt-1 bg-white border border-gray-200 rounded-md shadow-lg max-h-60 overflow-y-auto">
          {suggestions.map((suggestion) => (
            <button
              key={suggestion.user_id}
              type="button"
              className="w-full text-left px-4 py-2 hover:bg-gray-100 focus:bg-gray-100 focus:outline-none"
              onClick={() => handleSuggestionClick(suggestion)}
            >
              <div className="flex flex-col">
                <span className="font-medium">@{suggestion.username}</span>
                {suggestion.name && (
                  <span className="text-sm text-gray-500">
                    {suggestion.name}
                  </span>
                )}
              </div>
            </button>
          ))}
        </div>
      )}

      {loading && (
        <div className="absolute right-3 top-1/2 transform -translate-y-1/2">
          <div className="w-4 h-4 border-2 border-gray-300 border-t-blue-500 rounded-full animate-spin"></div>
        </div>
      )}
    </div>
  );
};

export default AutocompleteInput;
