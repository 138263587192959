import React, { useState, useRef, useEffect } from "react";
import { EyeIcon, EyeOffIcon, ChevronDownIcon, Check } from "lucide-react";
import { inputStyles } from "../../config/styles/Input";
import { buttonStyles } from "../../config/styles/Button";

const Input = ({
  label,
  onChange,
  value,
  name,
  disabled = false,
  type = "text",
  placeholder = "",
  options = [],
  className = "",
  required = false,
  errorMessage = "",
  prefixIcon,
  suffixIcon,
  dropdownPosition = "auto", // Can be 'auto', 'top', or 'bottom'
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [dropdownTop, setDropdownTop] = useState(false);
  const dropdownRef = useRef(null);
  const containerRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (isOpen && dropdownPosition === "auto") {
      const updatePosition = () => {
        if (containerRef.current) {
          const rect = containerRef.current.getBoundingClientRect();
          const spaceBelow = window.innerHeight - rect.bottom;
          const spaceAbove = rect.top;
          const dropdownHeight = 208; // max-h-52 = 13rem = 208px

          setDropdownTop(spaceBelow < dropdownHeight && spaceAbove > spaceBelow);
        }
      };

      updatePosition();
      window.addEventListener('scroll', updatePosition);
      window.addEventListener('resize', updatePosition);

      return () => {
        window.removeEventListener('scroll', updatePosition);
        window.removeEventListener('resize', updatePosition);
      };
    }
  }, [isOpen, dropdownPosition]);

  const renderInput = () => {
    const commonProps = {
      className: `${inputStyles.base} ${className} font-sans ${
        prefixIcon ? "pl-10" : ""
      } ${suffixIcon || type === "password" ? "pr-10" : ""}`,
      placeholder,
      onChange,
      value,
      required,
      name,
    };

    const inputElement = () => {
      switch (type) {
        case "text":
        case "email":
        case "number":
          return (
            <input
              type={type}
              {...commonProps}
              className={`${commonProps.className} ${
                errorMessage && "border-red-500"
              } ${disabled && "cursor-not-allowed"}`}
              onChange={(e) => onChange(e)}
              disabled={disabled}
              value={value}
            />
          );
        case "password":
          return (
            <input
              type={showPassword ? "text" : "password"}
              {...commonProps}
              className={`${commonProps.className} ${
                errorMessage && "border-red-500"
              } ${disabled && "cursor-not-allowed"}`}
              disabled={disabled}
              onChange={(e) => onChange(e)}
              value={value}
            />
          );
        case "select":
          return (
            <div
              className={`${commonProps.className} ${
                disabled && "cursor-not-allowed opacity-60"
              } hover:border-gray-400 cursor-pointer flex items-center justify-between group`}
              onClick={() => !disabled && setIsOpen(!isOpen)}
              name={name}
            >
              <span
                className={`truncate ${
                  !value ? "text-gray-400" : "text-gray-700"
                }`}
              >
                {options.find((opt) => opt.value === value)?.label ||
                  placeholder}
              </span>
              <ChevronDownIcon
                className={`h-5 w-5 text-gray-400 transition-transform duration-200 flex-shrink-0 group-hover:text-gray-600 ${
                  isOpen ? "rotate-180" : ""
                }`}
              />
            </div>
          );
        case "textarea":
          return (
            <textarea
              {...commonProps}
              className={`${commonProps.className} ${inputStyles.textarea} ${
                disabled && "cursor-not-allowed"
              }`}
              disabled={disabled}
            />
          );
        case "checkbox":
          return (
            <div className="flex items-center">
              <input
                type="checkbox"
                {...commonProps}
                className="accent-[#191D23] h-5 w-5 text-primary-600 transition duration-150 ease-in-out"
                checked={value}
                onChange={onChange}
              />
              <span className="ml-2 text-gray-700">{label}</span>
            </div>
          );
        default:
          return null;
      }
    };

    const shouldShowDropdownOnTop = dropdownPosition === "top" || 
      (dropdownPosition === "auto" && dropdownTop);

    return (
      <div className="relative w-full">
        {prefixIcon && type !== "checkbox" && (
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            {prefixIcon}
          </div>
        )}
        {inputElement()}
        {type === "select" && isOpen && (
          <div 
            className={`absolute z-10 w-full bg-white border border-gray-200 rounded-lg shadow-lg overflow-y-auto overscroll-contain max-h-52 scrollbar-thin scrollbar-thumb-gray-200 scrollbar-track-transparent
              ${shouldShowDropdownOnTop ? 'bottom-full mb-1' : 'top-full mt-1'}`}
          >
            {options.map((option, index) => (
              <React.Fragment key={index}>
                {option.value === "create-new-room" ? (
                  <div
                    className="sticky bottom-0 px-3.5 py-2.5 text-blue-600 hover:bg-blue-50 cursor-pointer text-sm font-medium text-center border-t border-gray-100 bg-white"
                    onClick={() => {
                      onChange({ target: { value: option.value } });
                      setIsOpen(false);
                    }}
                  >
                    {option.label}
                  </div>
                ) : (
                  <div
                    className={`px-3.5 py-2.5 cursor-pointer text-sm flex items-center justify-between
                      ${
                        option.value === value
                          ? "bg-blue-50 text-blue-700"
                          : "text-gray-700 hover:bg-gray-50"
                      }`}
                    onClick={() => {
                      onChange({ target: { value: option.value } });
                      setIsOpen(false);
                    }}
                  >
                    {option.label}
                    {option.value === value && (
                      <Check className="h-4 w-4 text-blue-600 flex-shrink-0" />
                    )}
                  </div>
                )}
              </React.Fragment>
            ))}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className={`${inputStyles.container}`} ref={(el) => {
      dropdownRef.current = el;
      containerRef.current = el;
    }}>
      {type !== "checkbox" && label && (
        <label className={`${inputStyles.label}`}>{label}</label>
      )}
      <div className={inputStyles.inputContainer}>
        {renderInput()}
        {errorMessage && (
          <small className="text-red-500 text-xs mt-1">{errorMessage}</small>
        )}
      </div>
    </div>
  );
};

export default Input;