import { Clock, Coins, ShieldCheck, Users } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "../../context/SnackbarContext";
import {
  formatDateTimeWithDay,
  formatIndianAmount,
} from "../../utilities/commonFunction";

const GameEntry = ({
  gameId,
  amount,
  date,
  type,
  venue,
  isActive,
  buyIn,
  duration,
  players,
  playersPrivacy,
  currency = "$",
  role = "player",
}) => {
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();

  return (
    <div
      className={`cursor-pointer font-medium border rounded-lg border-[#676D75] p-4 mb-2 shadow-md hover:shadow-lg transition-shadow duration-200 ${
        isActive &&
        "bg-gradient-to-r from-green-50 to-white border-l-[6px] border-l-green-500 border-y border-r"
      }`}
      onClick={() => navigate(`/game-details/${gameId}`)}
    >
      <div className="flex justify-between items-start">
        <div>
          <div className="flex gap-2 items-center">
            {type} {playersPrivacy && <ShieldCheck size={12} />}
          </div>
          <div className="text-sm">{venue}</div>
        </div>
        {isActive ? (
          <div className="inline-flex items-center gap-1.5 text-green-700 ">
            <div className="w-2 h-2 bg-green-500 rounded-full animate-pulse" />
            <span className="text-sm">Live</span>
          </div>
        ) : (
          <div className="flex flex-col items-end">
            <span
              className={`font-md text-2xl flex gap-0 items-center ${
                role === "player" &&
                (amount >= 0 ? "text-green-600" : "text-red-600")
              }`}
            >
              {amount && <>{formatIndianAmount(amount, currency)}</>}
            </span>
          </div>
        )}
      </div>

      <div className="mt-2 flex items-center justify-between text-[#292F33] text-xs">
        <div>{formatDateTimeWithDay(date)}</div>
        <div className="flex items-center gap-3">
          <div className="flex items-center gap-1">
            <Users size={14} />
            <span>{players}</span>
          </div>
          {isActive ? (
            <div className="flex items-center gap-1">
              <Coins size={14} />
              <span>&#8377;{buyIn}</span>
            </div>
          ) : (
            <div className="flex items-center gap-1">
              <Clock size={14} />
              <span>{duration}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default GameEntry;
