import { formatIndianAmount } from "../../utilities/commonFunction";

const Summary = ({
  totalGames,
  totalPnL,
  totalPlayers,
  currency,
  role = "player",
}) => {
  return (
    <div className="mb-4 transition-opacity duration-300 ease-in-out">
      <div className="grid grid-cols-2 gap-8">
        {role === "player" ? (
          <div>
            <div className="text-gray-600 mb-2">Total PnL</div>
            <div
              className={`text-4xl font-bold ${
                totalPnL >= 0 ? "text-green-500" : "text-red-500"
              }`}
              style={{ fontFamily: '"Bricolage Grotesque", sans-serif' }}
            >
              {formatIndianAmount(totalPnL,currency) || 0}
            </div>
          </div>
        ) : (
          <div>
            <div className="text-gray-600 mb-2">Total Players</div>
            <div
              className={`text-4xl font-bold`}
              style={{ fontFamily: '"Bricolage Grotesque", sans-serif' }}
            >
              {totalPlayers}
            </div>
          </div>
        )}
        <div>
          <div className="text-gray-600 mb-2">
            Total Games {role === "player" ? "Played" : "Hosted"}
          </div>
          <div
            className="text-4xl font-bold"
            style={{ fontFamily: '"Bricolage Grotesque", sans-serif' }}
          >
            {totalGames}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Summary;
