import React, { useState, useRef, useEffect } from "react";
import { ChevronDown, ChevronUp, AlertCircle } from "lucide-react";
import Button from "../Button/Button";

const Table = ({
  columns,
  data,
  actionButtons,
  expandableContent,
  customStyleClasses = "",
}) => {
  const [expandedRows, setExpandedRows] = useState({});
  const [tableHeight, setTableHeight] = useState("auto");
  const tableRef = useRef(null);

  useEffect(() => {
    const updateTableHeight = () => {
      if (tableRef.current) {
        const newHeight = Math.min(
          tableRef.current.scrollHeight,
          window.innerHeight * 0.8
        );
        setTableHeight(`${newHeight}px`);
      }
    };

    updateTableHeight();
    window.addEventListener("resize", updateTableHeight);
    return () => window.removeEventListener("resize", updateTableHeight);
  }, [data, expandedRows]);

  const toggleRowExpansion = (rowIndex) => {
    setExpandedRows((prev) => ({
      ...prev,
      [rowIndex]: !prev[rowIndex],
    }));
  };

  const renderCell = (row, column) => {
    if (column.render) {
      return column.render(row);
    }
    return row[column.key];
  };

  if (!data?.length) {
    return (
      <div className="flex flex-col items-center justify-center w-full h-64 bg-white rounded-md shadow-md">
        <AlertCircle className="w-12 h-12 text-gray-400 mb-4" />
        <p className="text-lg text-gray-600 font-medium">No data available</p>
      </div>
    );
  }

  return (
    <div
      ref={tableRef}
      style={{ height: tableHeight }}
      className={`flex flex-col w-full bg-white rounded-md shadow-md overflow-hidden ${customStyleClasses}`}
    >
      <div className="overflow-auto flex-grow">
        <table className="w-full">
          <thead className="sticky top-0 bg-gray-800 text-white z-20">
            <tr>
              {columns.map((column, index) => (
                <th
                  key={index}
                  className="py-4 px-2 text-left whitespace-nowrap font-semibold text-md"
                  style={{ fontFamily: '"Bricolage Grotesque", sans-serif' }}
                >
                  {column.header}
                </th>
              ))}
              {actionButtons && <th className="px-2 py-4"></th>}
            </tr>
          </thead>
          <tbody>
            {data?.map((row, rowIndex) => (
              <React.Fragment key={rowIndex}>
                <tr className="border-b border-gray-200 text-sm">
                  {columns.map((column, colIndex) => (
                    <td key={colIndex} className="p-2 whitespace-nowrap">
                      {renderCell(row, column)}
                    </td>
                  ))}
                  {actionButtons && (
                    <td className="p-2 whitespace-nowrap">
                      <div className="flex justify-end gap-2">
                        {actionButtons.map((button, btnIndex) => (
                          <Button
                            variant="iconRound"
                            key={btnIndex}
                            icon={button?.icon}
                            disabled={
                              button?.isDisabled
                                ? button?.isDisabled(row)
                                : false
                            }
                            onClick={() => button.onClick(row)}
                          />
                        ))}
                        {expandableContent && (
                          <Button
                            variant="iconRound"
                            icon={
                              expandedRows[rowIndex] ? (
                                <ChevronUp size={20} color="#EE203D" />
                              ) : (
                                <ChevronDown size={20} color="#EE203D" />
                              )
                            }
                            onClick={() => toggleRowExpansion(rowIndex)}
                          />
                        )}
                      </div>
                    </td>
                  )}
                </tr>
                {expandedRows[rowIndex] && expandableContent && (
                  <tr className="bg-gray-50">
                    <td
                      colSpan={columns.length + (actionButtons ? 1 : 0)}
                      className="p-2"
                    >
                      {typeof expandableContent === "function"
                        ? expandableContent(row)
                        : expandableContent}
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Table;
