import { useState, useEffect } from "react";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import GameEntry from "../../components/GameEntry/GameEntry";
import Tabs from "../../components/Tabs/Tabs";
import Summary from "./Summary";
import { ChevronLeft, ListFilter } from "lucide-react";
import Button from "../../components/Button/Button";
import GameFilter from "./GameFilter";
import Loader from "../../components/Loader/Loader";
import { API } from "../../services";

const Games = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  // Initialize state from URL parameters
  const [activeTab, setActiveTab] = useState(
    searchParams.get("role") || "player"
  );
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [games, setGames] = useState([]);
  const [rooms, setRooms] = useState([]);
  const [summary, setSummary] = useState({
    totalGames: 0,
    totalPnL: 0,
  });

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const pageSize = 10;

  // Default filters state
  const defaultFilters = {
    dateRange: "all",
    startDate: null,
    endDate: null,
    gameType: "all",
    location: "all",
    sortBy: "date_newest",
    roomId: null,
  };

  // Initialize filters from URL parameters or defaults
  const [filters, setFilters] = useState(() => ({
    dateRange: searchParams.get("dateRange") || defaultFilters.dateRange,
    startDate: searchParams.get("startDate") || defaultFilters.startDate,
    endDate: searchParams.get("endDate") || defaultFilters.endDate,
    gameType: searchParams.get("gameType") || defaultFilters.gameType,
    location: searchParams.get("location") || defaultFilters.location,
    sortBy: searchParams.get("sortBy") || defaultFilters.sortBy,
    roomId: searchParams.get("roomId") || defaultFilters.roomId,
  }));

  const tabs = [
    { id: "player", label: "As player" },
    { id: "host", label: "As host" },
  ];

  // Reset states function
  const resetStates = () => {
    setGames([]);
    setCurrentPage(1);
    setHasMore(true);
    setError(null);
    setSummary({
      totalGames: 0,
      totalPnL: 0,
    });
  };

  // Reset filters function
  const resetFilters = () => {
    setFilters(defaultFilters);
  };

  // Update URL with current filters and tab
  const updateURL = (newFilters, newTab) => {
    const params = new URLSearchParams();

    // Add non-default filter values to URL
    Object.entries(newFilters).forEach(([key, value]) => {
      if (value && value !== defaultFilters[key]) {
        params.set(key, value);
      }
    });

    // Add role if not player
    if (newTab !== "player") {
      params.set("role", newTab);
    }

    navigate(`${location.pathname}?${params.toString()}`, { replace: true });
  };

  // Fetch games function
  const fetchGames = async (page = 1, loadMore = false) => {
    try {
      setIsLoading(true);
      setError(null);

      const queryParams = new URLSearchParams({
        page: page.toString(),
        pageSize: pageSize.toString(),
        role: activeTab,
      });

      // Add filters to query params
      Object.entries(filters).forEach(([key, value]) => {
        if (value && value !== defaultFilters[key]) {
          queryParams.append(key, value);
        }
      });

      const { data } = await API.getGamesList(
        queryParams,
        localStorage.getItem("token")
      );

      if (data.success) {
        if (loadMore) {
          setGames((prevGames) => [...prevGames, ...data.data]);
        } else {
          setGames(data.data);
          setSummary(data.summary);
          setRooms(data?.rooms);
        }
        setHasMore(data.pagination?.hasNextPage ?? false);
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  // Effect for URL parameter changes
  useEffect(() => {
    const newRole = searchParams.get("role") || "player";
    if (activeTab !== newRole) {
      setActiveTab(newRole);
    }
  }, [searchParams]);

  // Effect for tab changes
  useEffect(() => {
    resetStates();
    resetFilters();
    fetchGames(1, false);
    updateURL(filters, activeTab);
  }, [activeTab]);

  // Effect for filter changes
  useEffect(() => {
    resetStates();
    fetchGames(1, false);
    updateURL(filters, activeTab);
  }, [filters]);

  const loadMore = () => {
    if (hasMore && !isLoading) {
      const nextPage = currentPage + 1;
      setCurrentPage(nextPage);
      fetchGames(nextPage, true);
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  const handleFilterApply = (newFilters) => {
    setFilters(newFilters);
  };

  const handleTabChange = (newTab) => {
    setActiveTab(newTab);
  };

  const formatGameEntry = (game) => ({
    gameId: game.id,
    amount:
      activeTab === "player" ? game.financials.pnl : game.financials.totalBuyIn,
    date: game.date,
    type: game.title,
    venue: game.room,
    duration: game.duration,
    players: game.playerCount,
    buyIn: game.financials.totalBuyIn?.toLocaleString(),
    isActive: game.status === "active",
    playersPrivacy: game?.playersPrivacy,
    isHost: game?.isHost,
    isAdmin: game?.isAdmin,
    currency: game?.currencySymbol,
  });

  return (
    <div className="max-w-3xl p-4 pt-0 mx-auto flex flex-col gap-2 h-[calc(100%-5rem)] overflow-auto">
      <header className="flex items-center mb-4 pt-4 sticky top-0 z-20 bg-white">
        <button onClick={handleBack} className="py-2">
          <ChevronLeft size={24} />
        </button>
        <h1 className="text-md font-bold w-full text-center">Games</h1>
      </header>

      <Tabs tabs={tabs} activeTab={activeTab} onTabChange={handleTabChange} />

      {isLoading && games.length === 0 ? (
        <div className="mt-4">
          <Loader
            context={activeTab === "player" ? "gamesAsPlayer" : "gamesAsHost"}
          />
        </div>
      ) : (
        <>
          <Summary
            totalGames={summary.totalGames}
            currency={summary.currencySymbol}
            totalPnL={summary.totalPnL}
            totalPlayers={summary.totalPlayers}
            role={activeTab}
          />

          <div className="w-full flex justify-end">
            <Button
              variant="outlinedWithIcon"
              onClick={() => setIsFilterOpen(true)}
            >
              <ListFilter />
            </Button>
          </div>

          {error && (
            <div className="text-red-500 text-center py-4">Error: {error}</div>
          )}

          <div className="">
            {games.map((game, index) => (
              <GameEntry
                key={game.id || index}
                {...formatGameEntry(game)}
                role={activeTab}
              />
            ))}

            {hasMore && (
              <Button
                variant="outlined"
                className="w-full mt-4"
                onClick={loadMore}
                disabled={isLoading}
              >
                {isLoading ? "Loading..." : "Load More"}
              </Button>
            )}

            {games.length === 0 && !isLoading && (
              <div className="text-center py-4 text-gray-500">
                No games found
              </div>
            )}
          </div>
        </>
      )}

      <GameFilter
        rooms={rooms}
        isOpen={isFilterOpen}
        onClose={() => setIsFilterOpen(false)}
        onApply={handleFilterApply}
        initialFilters={filters}
      />
    </div>
  );
};

export default Games;
