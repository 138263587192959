const formatDateTime = (timestamp) => {
  if (!timestamp) {
    return "";
  }

  const date = new Date(timestamp);

  const optionsDate = {
    day: "2-digit",
    month: "short",
    year: "2-digit",
  };

  const optionsTime = {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  };

  const formattedDate = date.toLocaleDateString("en-GB", optionsDate);
  const formattedTime = date.toLocaleTimeString("en-US", optionsTime);

  return `${formattedDate}, ${formattedTime}`;
};

const formatDateTimeWithDay = (timestamp) => {
  // Return empty string if no timestamp provided
  if (!timestamp) {
    return "";
  }

  try {
    // Create Date object from UTC timestamp
    const date = new Date(timestamp);

    // Check if date is valid
    if (isNaN(date.getTime())) {
      throw new Error("Invalid date");
    }

    // Array of three-letter day names
    const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

    // Get components in local timezone
    const dayName = days[date.getDay()];
    const month = date.toLocaleString("en-US", {
      month: "short",
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });
    const day = date.getDate();
    const year = date.getFullYear();

    // Return formatted string
    return `${dayName}, ${month} ${day} ${year}`;
  } catch (error) {
    console.error("Error formatting date:", error);
    return "";
  }
};
const formatDateTimeShort = (timestamp) => {
  // Return empty string if no timestamp provided
  if (!timestamp) {
    return "";
  }

  try {
    // Create Date object from UTC timestamp
    const date = new Date(timestamp);

    // Check if date is valid
    if (isNaN(date.getTime())) {
      throw new Error("Invalid date");
    }

    // Get month abbreviation in local timezone
    const month = date.toLocaleString("en-US", {
      month: "short",
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });

    // Get day of month in local timezone
    const day = date.getDate();

    // Get hours and minutes in local timezone
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");

    // Return formatted string with the specific spacing
    return `${month} ${day} - ${hours}:${minutes}`;
  } catch (error) {
    console.error("Error formatting date:", error);
    return "";
  }
};

const formatAmount = (value) => {
  if (!value && typeof value !== "number") return "";
  return Number(value).toLocaleString("en-IN");
};

const formatDisplayValue = (val) => {
  if (val === "") return "";
  const number = Number(val);
  return isNaN(number) ? "" : number.toLocaleString("en-IN");
};

const formatIndianAmount = (value, currency = "$") => {
  if (!value) return "";

  const number = Number(value);
  if (isNaN(number)) return "";

  const absolute = Math.abs(number);
  const sign = number < 0 ? "- " : " ";

  const formatDecimal = (num) => {
    // Convert to string with 2 decimal places
    const withDecimal = num.toFixed(2);
    // Remove trailing zeros and decimal point if not needed
    return withDecimal.replace(/\.?0+$/, "");
  };

  if (absolute >= 10000000) {
    // 1 crore = 10000000
    return sign + currency + formatDecimal(absolute / 10000000) + "Cr";
  } else if (absolute >= 100000) {
    // 1 lakh = 100000
    return sign + currency + formatDecimal(absolute / 100000) + "L";
  } else if (absolute >= 1000) {
    // 1 thousand = 1000
    return sign + currency + formatDecimal(absolute / 1000) + "K";
  } else {
    return sign + currency + absolute.toLocaleString("en-IN");
  }
};

export {
  formatDateTime,
  formatAmount,
  formatDisplayValue,
  formatIndianAmount,
  formatDateTimeWithDay,
  formatDateTimeShort,
};
