export const inputStyles = {
    base: "w-full px-3.5 py-2.5 rounded-lg border border-gray-300 bg-white text-gray-700 text-base focus:outline-none focus:border-gray-400 shadow-xs",
    container: "flex flex-col items-start gap-1.5 w-full",
    inputContainer: "w-full",
    label: "text-sm font-medium text-gray-700",
    passwordButton: "absolute inset-y-0 right-0 pr-3 flex items-center",
    select: {
      container: "relative w-full",
      button: "cursor-pointer flex items-center justify-between",
      options: "absolute z-10 w-full mt-1 bg-white border border-gray-300 rounded-lg shadow-lg max-h-60 overflow-auto",
      option: "px-3.5 py-2.5 cursor-pointer hover:bg-gray-100 text-gray-700",
    },
    textarea: "min-h-[80px]",
  };