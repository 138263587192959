const Tabs = ({ tabs, activeTab, onTabChange }) => {
  return (
    <div className={`grid grid-cols-${tabs?.length} my-4 border-b relative`}>
      {tabs.map((tab) => (
        <button
          key={tab.id}
          onClick={() => onTabChange(tab.id)}
          className={`pb-2 relative transition-colors duration-200 text-center ${
            activeTab === tab.id
              ? "text-[#EE203D]"
              : "text-[#49454F] hover:text-[#3c3941]"
          }`}
        >
          {tab.label}
        </button>
      ))}
      {/* Sliding underline */}
      <div
        className="absolute bottom-0 h-[3px] bg-[#EE203D] rounded-t-lg transition-all duration-300 ease-in-out"
        style={{
          width: `${100 / tabs.length}%`,
          left: `${
            (tabs.findIndex((tab) => tab.id === activeTab) * 100) / tabs.length
          }%`,
        }}
      />
    </div>
  );
};

export default Tabs;
