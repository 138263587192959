import React from "react";
import Table from "../../components/Table/Table";

const SettlementSection = ({ settlements = [], currencySymbol = "₹" }) => {
  const transformSettlements = (settlements) => {
    return settlements.map((settlement) => {
      if (settlement.type === "settled") {
        return {
          content: `${settlement.player_name} is settled`,
        };
      }

      if (settlement.type === "unsettled_debt") {
        return {
          content: (
            <span>
              {settlement.player_name} owes{" "}
              <span className="text-red-500">
                {currencySymbol}
                {Math.abs(settlement.amount)}
              </span>{" "}
              (unsettled)
            </span>
          ),
        };
      }

      if (settlement.type === "mismatch" && settlement.isSurplus) {
        return {
          content: (
            <span>
              Surplus amount{" "}
              <span className="text-green-500">
                {currencySymbol}
                {Math.abs(settlement.amount)}
              </span>
            </span>
          ),
        };
      }

      // For creditor settlements
      return {
        content: (
          <div className="space-y-2">
            <div>
              {settlement.creditor_name} wins{" "}
              <span className="text-green-500">
                {currencySymbol}
                {settlement.amount}
              </span>{" "}
              in total
            </div>
            <div className="pl-2 ml-8 border-l-2 border-gray-300">
              {settlement.debtors?.map((debtor, idx) => (
                <div
                  key={debtor.debtor_id || `debtor-${idx}`}
                  className="text-gray-600"
                >
                  {debtor.type === "mismatch" && debtor.isMismatchOwed ? (
                    <span>
                      Mismatch amount owed:{" "}
                      <span className="text-red-500">
                        {currencySymbol}
                        {debtor.amount}
                      </span>
                    </span>
                  ) : (
                    <span>
                      {debtor.debtor_name} owes{" "}
                      <span className="text-red-500">
                        {currencySymbol}
                        {debtor.amount}
                      </span>
                    </span>
                  )}
                </div>
              ))}
            </div>
          </div>
        ),
      };
    });
  };

  const columns = [
    {
      key: "content",
      header: "SETTLEMENTS",
      render: (row) => <div className="p-2">{row.content}</div>,
    },
  ];

  return (
    <div className="mt-4">
      <Table
        columns={columns}
        data={transformSettlements(settlements)}
        customStyleClasses="rounded-lg shadow bg-white"
      />
    </div>
  );
};

export default SettlementSection;
