import React from "react";
import { buttonStyles } from "../../config/styles/Button";
import { ReactComponent as GoogleIcon } from "../../assets/icons/google.svg";

const Button = ({
  children,
  variant = "primary",
  fullWidth = false,
  icon,
  onClick,
  disabled = false,
  className = "",
}) => {
  const classes = `
    ${buttonStyles.base}
    ${buttonStyles.variants[variant]}
    ${fullWidth ? buttonStyles.fullWidth : ""}
    ${className}
  `;

  return (
    <button className={classes} onClick={onClick} disabled={disabled}>
      {variant === "google" ? (
        <GoogleIcon />
      ) : (
        icon && <span>{icon}</span>
      )}
      {children}
    </button>
  );
};

export default Button;
