import React, { useState, useCallback } from "react";
import { ChevronLeft } from "lucide-react";
import { ReactComponent as RoomIcon } from "../../assets/icons/create-room.svg";
import { ReactComponent as DeleteIcon } from "../../assets/icons/delete-list-item.svg";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button/Button";
import Input from "../../components/Input/Input";
import { useSnackbar } from "../../context/SnackbarContext";
import { API } from "../../services";
import AutocompleteInput from "../../components/AutoCompleteInput/AutoCompleteInput";

const CreateNewRoom = () => {
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const [roomName, setRoomName] = useState("");
  const [roomLocation, setRoomLocation] = useState("");
  const [description, setDescription] = useState("");
  const [admins, setAdmins] = useState([""]);
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleAdminChange = (index, event) => {
    const newAdmins = [...admins];
    newAdmins[index] = event.target.value.toLowerCase();
    setAdmins(newAdmins);
  };

  const handleRemoveAdmin = (index) => {
    const newAdmins = admins.filter((_, i) => i !== index);
    setAdmins(newAdmins);
  };

  const handleBack = () => {
    navigate(-1);
  };

  const handleAddAdmin = () => {
    if (admins.length < 2) {
      setAdmins([...admins, ""]);
    }
  };

  const validateForm = useCallback(() => {
    const newErrors = {};
    if (!roomName.trim()) newErrors.roomName = "Room name is required";
    if (!roomLocation.trim())
      newErrors.roomLocation = "Room location is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  }, [roomName, roomLocation]);

  const handleSubmit = async () => {
    if (!validateForm()) return;

    setIsSubmitting(true);
    try {
      const filteredAdmins = admins.filter((admin) => admin.trim() !== "");
      const payload = {
        room_name: roomName,
        room_location: roomLocation,
        admins: filteredAdmins,
        description,
      };
      await API.createRoom(payload, localStorage.getItem("token"));
      navigate(-1);
    } catch (error) {
      const message = error?.response?.data?.error;
      showSnackbar(
        message || "Failed to create room. Please try again.",
        "error"
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="flex flex-col items-center min-h-screen bg-white p-4">
      <div className="w-full max-w-md space-y-8">
        <div className="space-y-9">
          <div className="flex flex-col gap-8">
            <button onClick={handleBack}>
              <ChevronLeft size={24} />
            </button>
            <div className="flex flex-col gap-3 items-center">
              <div className="rounded-[10px] border border-gray-200 bg-white shadow-sm p-3">
                <RoomIcon />
              </div>
              <h2
                className="text-center text-[24px] font-semibold leading-[130%] tracking-[-0.64px] text-[#1A1C1E]"
                style={{ fontFamily: '"Bricolage Grotesque", sans-serif' }}
              >
                Create New Room
              </h2>
            </div>
          </div>

          <div className="flex flex-col gap-6">
            <div className="space-y-4">
              <h2 className="text-lg font-semibold">Room Information</h2>
              <div className="space-y-4">
                <Input
                  type="text"
                  placeholder="Room Name, eg. Phil's Room"
                  value={roomName}
                  onChange={(e) => {
                    setRoomName(e.target.value);
                    setErrors((prev) => ({ ...prev, roomName: "" }));
                  }}
                  name="roomName"
                  errorMessage={errors.roomName}
                />
                <Input
                  type="text"
                  placeholder="Room Location, eg. Las Vegas..."
                  value={roomLocation}
                  onChange={(e) => {
                    setRoomLocation(e.target.value);
                    setErrors((prev) => ({ ...prev, roomLocation: "" }));
                  }}
                  name="roomLocation"
                  errorMessage={errors.roomLocation}
                />
                <Input
                  type="textarea"
                  placeholder="Room Description (Optional)"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  name="description"
                  className="w-full h-24"
                />
              </div>
            </div>

            <div className="space-y-4">
              <h2 className="text-lg font-semibold">Admins (Optional)</h2>
              <div className="space-y-3">
                {admins.map((admin, index) => (
                  <div key={index} className="flex gap-2">
                    <AutocompleteInput
                      value={admin}
                      onChange={(e) => handleAdminChange(index, e)}
                      placeholder="Username"
                      disabled={isSubmitting}
                      className="flex-1"
                    />
                    <button
                      type="button"
                      onClick={() => handleRemoveAdmin(index)}
                      className="text-red-500 p-2"
                      disabled={isSubmitting}
                    >
                      <DeleteIcon />
                    </button>
                  </div>
                ))}
                {admins.length < 2 && (
                  <button
                    type="button"
                    className="group inline-flex flex-col items-start"
                    onClick={handleAddAdmin}
                    disabled={isSubmitting}
                  >
                    <span className="text-sm text-gray-900 underline underline-offset-4">
                      +Add more admins
                    </span>
                  </button>
                )}
              </div>
            </div>

            <Button
              variant="primary"
              className="w-full mt-4"
              onClick={handleSubmit}
              disabled={isSubmitting}
            >
              {isSubmitting ? "Creating..." : "Create new room"}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateNewRoom;
