import axios from "axios";

let BASE_URL =
  process.env.REACT_APP_ENVIRONMENT === "production"
    ? "https://api.chipcount.xyz"
    : "http://localhost:5000";
const API = {
  // Auth endpoints
  registerUser: (payload) => {
    const url = `${BASE_URL}/auth/register`;
    return axios.post(url, payload);
  },
  registerUserWithGoogle: (payload) => {
    const url = `${BASE_URL}/auth/register-google`;
    return axios.post(url, payload);
  },
  googleLogin: (payload) => {
    const url = `${BASE_URL}/auth/google`;
    return axios.post(url, payload);
  },
  userLogin: (payload) => {
    const url = `${BASE_URL}/auth/login`;
    return axios.post(url, payload);
  },
  logout: (token, googleToken) => {
    const url = `${BASE_URL}/auth/logout`;
    const headers = {
      "Content-Type": "application/json",
      token,
      ...(googleToken && { googleToken }),
    };
    return axios.post(url, {}, { headers });
  },

  // Game endpoints
  getGameDetailsFromGameId: (gameId, token) => {
    const url = `${BASE_URL}/games/${gameId}`;
    return axios.get(url, {
      headers: { token },
    });
  },
  getGameSettlementDetailsFromGameId: (gameId, token) => {
    const url = `${BASE_URL}/games/settlement/${gameId}`;
    return axios.get(url, {
      headers: { token },
    });
  },
  updateGameDetailsForGameId: (gameId, payload, token) => {
    const url = `${BASE_URL}/games/${gameId}`;
    return axios.put(url, payload, {
      headers: { token },
    });
  },
  endGame: (gameId, payload, token) => {
    const url = `${BASE_URL}/games/${gameId}/end`;
    return axios.put(url, payload, {
      headers: { token },
    });
  },
  deleteGame: (gameId, token) => {
    const url = `${BASE_URL}/games/${gameId}`;
    return axios.delete(url, {
      headers: { token },
    });
  },
  createGame: (payload, token) => {
    const url = `${BASE_URL}/games`;
    return axios.post(url, payload, {
      headers: { token },
    });
  },

  // Player endpoints
  searchRoomUsers: (query, token) => {
    const url = `${BASE_URL}/games/search-users?query=${encodeURIComponent(
      query
    )}`;
    return axios.get(url, {
      headers: { token },
    });
  },
  addPlayerToGame: (payload, token) => {
    const url = `${BASE_URL}/games/add-players`;
    return axios.post(url, payload, {
      headers: { token },
    });
  },
  updatePlayerDetails: (gameId, playerId, payload, token) => {
    const url = `${BASE_URL}/games/${gameId}/players/${playerId}`;
    return axios.put(url, payload, {
      headers: { token },
    });
  },
  topUpPlayer: (gameId, playerId, payload, token) => {
    const url = `${BASE_URL}/games/${gameId}/players/${playerId}/topup`;
    return axios.post(url, payload, {
      headers: { token },
    });
  },
  chipOutPlayer: (gameId, playerId, payload, token) => {
    const url = `${BASE_URL}/games/${gameId}/players/${playerId}/chipout`;
    return axios.post(url, payload, {
      headers: { token },
    });
  },
  deletePlayer: (gameId, playerId, token) => {
    const url = `${BASE_URL}/games/${gameId}/players/${playerId}`;
    return axios.delete(url, {
      headers: { token },
    });
  },

  // History and logs
  getGamesList: (queryParams, token) => {
    const url = `${BASE_URL}/games?${queryParams}`;
    return axios.get(url, {
      headers: { token },
    });
  },
  getPlayerHistory: (gameId, playerId, token) => {
    const url = `${BASE_URL}/games/${gameId}/player-actions/${playerId}`;
    return axios.get(url, {
      headers: { token },
    });
  },
  getGameHistory: (gameId, token) => {
    const url = `${BASE_URL}/games/${gameId}/game-actions`;
    return axios.get(url, {
      headers: { token },
    });
  },

  // Room endpoints
  getUserRoomsList: (token) => {
    const url = `${BASE_URL}/room/user-rooms`;
    return axios.get(url, {
      headers: { token },
    });
  },
  getRoomDetailsFromRoomId: (roomId, token) => {
    const url = `${BASE_URL}/room/rooms/${roomId}`;
    return axios.get(url, {
      headers: { token },
    });
  },
  updateRoomDetailsFromRoomId: (roomId, payload, token) => {
    const url = `${BASE_URL}/room/rooms/${roomId}`;
    return axios.put(url, payload, {
      headers: { token },
    });
  },
  createRoom: (payload, token) => {
    const url = `${BASE_URL}/room/rooms`;
    return axios.post(url, payload, {
      headers: { token },
    });
  },
  deleteRoom: (roomId, token) => {
    const url = `${BASE_URL}/room/rooms/${roomId}`;
    return axios.delete(url, {
      headers: { token },
    });
  },

  // Dashboard and user profile
  getDashboardData: (token) => {
    const url = `${BASE_URL}/games/dashboard-data`;
    return axios.get(url, {
      headers: { token },
    });
  },
  getUserProfile: (token) => {
    const url = `${BASE_URL}/user/profile`;
    return axios.get(url, {
      headers: { token },
    });
  },
  getGameDefaults: (token) => {
    const url = `${BASE_URL}/user/game-defaults`;
    return axios.get(url, {
      headers: { token },
    });
  },
  updateGameDefaults: (payload, token) => {
    const url = `${BASE_URL}/user/game-defaults`;
    return axios.put(url, payload, {
      headers: { token },
    });
  },
  updateUserProfile: (payload, token) => {
    const url = `${BASE_URL}/user/profile`;
    return axios.put(url, payload, {
      headers: { token },
    });
  },
  addFeedback: (payload, token) => {
    const url = `${BASE_URL}/user/feedback`;
    return axios.post(url, payload, {
      headers: { token },
    });
  },
};

export { API };
