import React, { useState } from "react";
import { Settings } from "lucide-react";
import Input from "../../components/Input/Input";
import Button from "../../components/Button/Button";
import { ReactComponent as Logo } from "../../assets/logo.svg";
import { useGoogleLogin } from "@react-oauth/google";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "../../context/SnackbarContext";
import { API } from "../../services";
import { useAuth } from "../../services/hooks/useAuth";

const LoginComponent = () => {
  const { showSnackbar } = useSnackbar();
  const { login } = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isGoogleLoading, setIsGoogleLoading] = useState(false);
  const navigate = useNavigate();

  const validateForm = () => {
    let formErrors = {};
    if (!email) {
      formErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      formErrors.email = "Email is invalid";
    }
    if (!password) {
      formErrors.password = "Password is required";
    } else if (password.length < 6) {
      formErrors.password = "Password must be at least 6 characters";
    }
    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setIsLoading(true);
      try {
        const payload = {
          email,
          password,
        };
        const response = await API.userLogin(payload);
        await login(response.data.token);
        showSnackbar("Login successful!", "success");
        navigate("/home");
      } catch (error) {
        const message = error?.response?.data?.error;
        showSnackbar(message || "Login failed. Please try again.", "error");
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleLoginSuccess = async (tokenResponse) => {
    setIsGoogleLoading(true);
    try {
      // Store Google token first
      await new Promise((resolve, reject) => {
        try {
          localStorage.setItem("googleToken", tokenResponse.access_token);
          resolve();
        } catch (error) {
          reject(error);
        }
      });

      const payload = {
        token: tokenResponse.access_token,
      };
      const {
        data: { type, token, name, email, googleId },
      } = await API.googleLogin(payload);

      if (type === "new_user") {
        navigate("/complete-registration", {
          state: {
            name,
            googleId,
            email,
          },
        });
      } else {
        await login(token);
        showSnackbar("Login successful!", "success");
        navigate("/home");
      }
    } catch (error) {
      const message = error?.response?.data?.error;
      showSnackbar(
        message || "Google login failed. Please try again.",
        "error"
      );
    } finally {
      setIsGoogleLoading(false);
    }
  };

  const handleLoginFailure = (error) => {
    showSnackbar(error || "Google login failed. Please try again.", "error");
    console.error(error);
    setIsGoogleLoading(false);
  };

  const googleLogin = useGoogleLogin({
    onSuccess: handleLoginSuccess,
    onError: handleLoginFailure,
  });

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-white p-4">
      <div className="w-full max-w-md space-y-8">
        <Logo />

        <div className="space-y-6">
          <h2
            className="text-[2rem] font-semibold mb-6 leading-[130%] tracking-[-0.64px] text-[#1A1C1E]"
            style={{ fontFamily: '"Bricolage Grotesque", sans-serif' }}
          >
            Sign in to your Account
          </h2>
          <p className="text-sm text-gray-600 text-left">
            Enter your email and password to sign in
          </p>

          <form className="space-y-4" onSubmit={handleSubmit}>
            <Input
              label="Email"
              type="text"
              placeholder="e.g. xyz@gmail.com"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                setErrors((prev) => ({ ...prev, email: "" }));
              }}
              errorMessage={errors?.email}
              disabled={isLoading}
            />

            <Input
              label="Password"
              type="password"
              placeholder="Enter your password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
                setErrors((prev) => ({ ...prev, password: "" }));
              }}
              errorMessage={errors?.password}
              disabled={isLoading}
            />

            <Button
              variant="primary"
              className="w-full"
              disabled={isLoading}
              loading={isLoading}
            >
              {isLoading ? "Logging in..." : "Login"}
            </Button>
          </form>

          <div className="relative">
            <div className="absolute inset-0 flex items-center">
              <div className="w-full border-t border-gray-300"></div>
            </div>
            <div className="relative flex justify-center text-sm">
              <span className="px-2 bg-white text-gray-500">Or</span>
            </div>
          </div>

          <Button
            variant="google"
            icon={<Settings size={20} />}
            className="w-full"
            type="button"
            onClick={() => !isGoogleLoading && googleLogin()}
            disabled={isLoading || isGoogleLoading}
            loading={isGoogleLoading}
          >
            {isGoogleLoading ? "Connecting..." : "Continue with Google"}
          </Button>

          <div className="w-full flex justify-center">
            <p
              className="flex items-center text-[0.75rem] font-medium text-[#6C7278] leading-[140%] tracking-[-0.12px]"
              style={{ fontFamily: "Inter, sans-serif" }}
            >
              Don't have an account?
              <Button
                variant="link"
                className="ml-1 p-0 text-blue-600 hover:text-blue-800 text-[0.75rem] font-medium leading-[140%] tracking-[-0.12px]"
                style={{ fontFamily: "Inter, sans-serif" }}
                onClick={() => navigate("/register")}
                disabled={isLoading || isGoogleLoading}
              >
                Sign Up
              </Button>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginComponent;
