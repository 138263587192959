import React, { useState } from "react";
import { X, DollarSign } from "lucide-react";
import { API } from "../../services";
import Input from "../../components/Input/Input";
import Button from "../../components/Button/Button";
import {
  formatAmount,
  formatDisplayValue,
} from "../../utilities/commonFunction";

const TopUpModal = ({
  isOpen,
  onClose,
  gameId,
  playerId,
  playerName,
  initialBuyIn,
  minBuyIn,
  updateGameDetails,
}) => {
  const [value, setValue] = useState("");
  const [error, setError] = useState("");

  if (!isOpen) return null;

  const handleQuickAdd = (amount) => {
    // Convert current value to number, defaulting to 0 if empty or NaN
    const currentValue =
      value === "" || isNaN(Number(value)) ? 0 : Number(value);
    setValue((currentValue + amount).toString());
  };

  // Handle input change with proper number formatting
  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    // Remove commas and any non-numeric characters except decimal point
    const cleanValue = inputValue.replace(/[^0-9.]/g, "");

    // Handle empty input
    if (cleanValue === "") {
      setValue("");
      return;
    }

    // Ensure valid number format
    const numberValue = Number(cleanValue);
    if (!isNaN(numberValue)) {
      setValue(cleanValue);
    }
  };

  const validateForm = () => {
    setError("");
    const numberValue = Number(value);
    if (!value || isNaN(numberValue) || numberValue <= 0) {
      setError("Please enter a valid amount");
      return false;
    }
    return true;
  };

  const handleSubmit = async () => {
    if (validateForm()) {
      try {
        const payload = {
          amount: Number(value),
        };

        const {
          data: { message, data },
        } = await API.topUpPlayer(
          gameId,
          playerId,
          payload,
          localStorage.getItem("token")
        );

        updateGameDetails((prevGameData) => {
          const updatedPlayers = prevGameData?.players.map((player) => {
            if (player.playerId === playerId) {
              return {
                ...player,
                buyIn: Number(data.current_buy_in),
              };
            }
            return player;
          });

          const totalBuyIn = updatedPlayers.reduce(
            (sum, player) => sum + player.buyIn,
            0
          );
          const avgBuyIn = totalBuyIn / updatedPlayers.length;

          return {
            ...prevGameData,
            players: updatedPlayers,
            stats: {
              ...prevGameData.stats,
              totalBuyIn,
              avgBuyIn: Math.round(avgBuyIn),
            },
          };
        });
        handleClose();
      } catch (error) {
        console.error("Error adding top-up:", error.message);
      }
    }
  };

  const handleClose = () => {
    setValue("");
    setError("");
    onClose();
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg shadow-lg w-full max-w-md">
        <div className="p-4">
          <div className="flex justify-between items-center mb-4">
            <div className="p-3 rounded-lg border border-[#E4E7EC] bg-white shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)]">
              <DollarSign size={24} />
            </div>
            <button
              onClick={handleClose}
              className="text-gray-400 hover:text-gray-600 transition-colors p-2"
              aria-label="Close modal"
            >
              <X size={24} />
            </button>
          </div>

          <h2 className="text-xl font-bold mb-2">
            Add top-up for {playerName}
          </h2>
          <p className="text-gray-600 mb-4">Please enter top-up value</p>

          <Input
            type="text"
            placeholder={`e.g. ${minBuyIn * 1}, ${minBuyIn * 1.5}`}
            value={formatDisplayValue(value)}
            onChange={handleInputChange}
            name="value"
            className="w-full p-3 border rounded-lg mb-4"
          />
          {error && <p className="text-red-500 text-sm mb-4">{error}</p>}

          <div className="w-full overflow-hidden">
            <div
              className={`grid ${
                minBuyIn >= 100000 ? "grid-cols-2" : "grid-cols-4"
              } gap-2 mb-6`}
            >
              {[minBuyIn * 1, minBuyIn * 2, minBuyIn * 3, minBuyIn * 5].map(
                (amount) => (
                  <button
                    key={amount}
                    onClick={() => handleQuickAdd(amount)}
                    className="bg-gray-100 text-gray-800 py-2 px-3 rounded-lg text-sm hover:bg-gray-200"
                  >
                    +{formatAmount(amount)}
                  </button>
                )
              )}
            </div>
          </div>

          <Button onClick={handleSubmit} variant="primary" className="w-full">
            Confirm
          </Button>
        </div>
      </div>
    </div>
  );
};

export default React.memo(TopUpModal);
