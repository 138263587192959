import { Edit2, Gamepad2Icon, MapPin, Users } from "lucide-react";
import { useNavigate } from "react-router-dom";
import Button from "../Button/Button";

const RoomEntry = ({ roomId, players, name, location, activeGames, games }) => {
  const navigate = useNavigate();

  const handleEditRoom = (e) => {
    e.stopPropagation();
    navigate(`/rooms/${roomId}/edit`);
  };

  const formatLocation = location => {
    return location.split(" ").join("+")
  }

  const handleNavigateToGames = () => {
    navigate(`/games?sortBy=date_newest&role=host&location=${formatLocation(name)}`);
  };
  return (
    <div
      className={`cursor-pointer font-medium border rounded-lg border-[#676D75] p-4 mb-2 shadow-md hover:shadow-lg transition-shadow duration-200
      ${
        activeGames &&
        "bg-gradient-to-r from-green-50 to-white border-l-[6px] border-l-green-500 border-y border-r"
      }`}
      onClick={handleNavigateToGames}
    >
      <div className="flex justify-between items-start">
        <div>
          <div className="flex gap-3 items-center">
            {name}
            <Button variant="iconFilled" onClick={handleEditRoom}>
              <Edit2 size={14} />
            </Button>
          </div>
          <div className="text-sm flex gap-1 items-center">
            <MapPin size={14} />
            {location}
          </div>
        </div>
        {activeGames !== 0 && (
          <div className="inline-flex items-center gap-1.5 text-green-700 ">
            <div className="w-2 h-2 bg-green-500 rounded-full animate-pulse" />
            <span className="text-sm">{activeGames} games Live</span>
          </div>
        )}
      </div>

      <div className="mt-2 flex items-center justify-between text-[#292F33] text-xs">
        <div className="flex items-center gap-3">
          <div className="flex items-center gap-1">
            <Users size={14} />
            <span>{players}</span>
          </div>
          <div className="flex items-center gap-1">
            <Gamepad2Icon size={14} />
            <span>{games}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RoomEntry;
