import React, { useState, useCallback, useEffect } from "react";
import { ChevronLeft } from "lucide-react";
import { ReactComponent as GameIcon } from "../../assets/icons/create-game.svg";
import { ReactComponent as RoomIcon } from "../../assets/icons/create-room.svg";
import { ReactComponent as DeleteIcon } from "../../assets/icons/delete-list-item.svg";
import Button from "../../components/Button/Button";
import Input from "../../components/Input/Input";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import Toggle from "../../components/Toggle/Toggle";
import { useSnackbar } from "../../context/SnackbarContext";
import { API } from "../../services";
import { CURRENCIES } from "../../utilities/commonValues";
import Loader from "../../components/Loader/Loader";
import AutocompleteInput from "../../components/AutoCompleteInput/AutoCompleteInput";

const handleNumberInput = (value) => {
  // Remove any non-numeric characters except decimal point
  const cleanValue = value.toString().replace(/[^0-9.]/g, "");

  // Handle empty input
  if (cleanValue === "") return "";

  // Ensure only one decimal point
  const parts = cleanValue.split(".");
  if (parts.length > 2) return parts[0] + "." + parts[1];

  // Convert to number and back to string to remove leading zeros
  const numberValue = Number(cleanValue);
  return isNaN(numberValue) ? "" : cleanValue;
};

const formatDisplayValue = (value) => {
  if (value === "" || value === null || value === undefined) return "";
  const number = Number(value);
  return isNaN(number) ? "" : number.toLocaleString();
};

const CreateNewGame = () => {
  const navigate = useNavigate();
  const { gameId } = useParams();
  const location = useLocation();
  const { showSnackbar } = useSnackbar();
  const [isEditMode, setIsEditMode] = useState(false);
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [roomOptions, setRoomOptions] = useState([]);
  const [formData, setFormData] = useState({
    room: "",
    min_buyin: "",
    gameType: "Cash Game",
    currency: "INR",
    type: "NLHE",
    limit: "no limit",
    smallBlind: "",
    bigBlind: "",
    admins: [""],
    addPlayersNow: false,
    playersPrivacy: false,
  });

  useEffect(() => {
    setIsEditMode(location.pathname.includes("/edit-game"));
  }, [location]);

  useEffect(() => {
    if (formData?.addPlayersNow) {
      setFormData((prev) => ({
        ...prev,
        players: [{ username: "", buyIn: formData?.min_buyin || "" }],
      }));
    }
  }, [formData?.addPlayersNow]);

  useEffect(() => {
    if (isEditMode && gameId) {
      const fetchData = async () => {
        try {
          setLoading(true);
          const {
            data: { data },
          } = await API.getGameDetailsFromGameId(
            gameId,
            localStorage.getItem("token")
          );
          setFormData({
            room: data?.roomId,
            min_buyin: data?.minBuyIn?.toString(),
            gameType: data?.gameType,
            currency: data?.currency,
            type: data?.type,
            limit: data?.limit,
            smallBlind: data?.smallBlind?.toString(),
            bigBlind: data?.bigBlind?.toString(),
            admins: data?.admins?.map((admin) => admin?.username) || [""],
            playersPrivacy: data?.playersPrivacy,
          });
        } catch (err) {
          setError("Failed to fetch game details.");
        } finally {
          setLoading(false);
        }
      };
      fetchData();
    } else {
      const fetchData = async () => {
        try {
          setLoading(true);
          const roomsResponse = await API.getUserRoomsList(
            localStorage.getItem("token")
          );
          setRoomOptions(
            roomsResponse?.data?.map((room) => ({
              label: room?.room_name,
              value: room?.room_id,
            }))
          );
        } catch (err) {
          showSnackbar("Failed to fetch rooms.", "error");
        } finally {
          setLoading(false);
        }
      };
      fetchData();
    }
  }, [isEditMode, gameId]);

  useEffect(() => {
    const fetchGameDefaults = async () => {
      try {
        if (!isEditMode) {
          const { data } = await API.getGameDefaults(
            localStorage.getItem("token")
          );
          if (data?.data) {
            setFormData((prev) => ({
              ...prev,
              min_buyin: data.data.buy_in?.toString() || "",
              gameType: data.data.game_type || "Cash Game",
              currency: data.data.currency || "INR",
              type: data.data.poker_type || "NLHE",
              limit: data.data.limit_type || "no limit",
              smallBlind: data.data.small_blind?.toString() || "",
              bigBlind: data.data.big_blind?.toString() || "",
              room: data.data.room_id?.toString() || "",
              playersPrivacy: data.data.players_privacy || false,
            }));
          }
        }
      } catch (error) {
        console.error("Error fetching game defaults:", error);
      }
    };

    fetchGameDefaults();
  }, [isEditMode]);

  const handleInputChange = (e, index = null, field = null) => {
    if (isEditMode && field !== "admins" && typeof e !== "boolean") {
      return;
    }

    if (typeof e === "boolean") {
      setFormData((prevData) => ({
        ...prevData,
        playersPrivacy: e,
      }));
    } else {
      const { name, value, type, checked } = e.target;

      // Handle number fields
      const numberFields = ["min_buyin", "smallBlind", "bigBlind"];
      const isNumberField =
        numberFields.includes(name) ||
        (field === "players" && name === "players.buyIn");

      setFormData((prevData) => {
        if (type === "checkbox") {
          return { ...prevData, [name]: checked };
        } else if (index !== null && field !== null) {
          const updatedArray = [...prevData[field]];
          if (field === "players") {
            const [_, fieldName] = name.split(".");
            let fieldValue;
            if (fieldName === "buyIn") {
              fieldValue = handleNumberInput(value);
            } else if (fieldName === "username") {
              fieldValue = value.toLowerCase();
            } else {
              fieldValue = value;
            }
            updatedArray[index] = {
              ...updatedArray[index],
              [fieldName]: fieldValue,
            };
          } else if (field === "admins") {
            updatedArray[index] = value.toLowerCase();
          } else {
            updatedArray[index] = value;
          }
          return { ...prevData, [field]: updatedArray };
        } else {
          const newValue = isNumberField ? handleNumberInput(value) : value;
          return { ...prevData, [name]: newValue };
        }
      });
      setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    }
  };

  const handleSelectChange = (name, value) => {
    if (isEditMode) return;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const addPlayer = () => {
    if (isEditMode) return;
    setFormData((prevData) => ({
      ...prevData,
      players: [
        ...prevData.players,
        { username: "", buyIn: formData?.min_buyin || "" },
      ],
    }));
  };

  const removePlayer = (index) => {
    if (isEditMode) return;
    setFormData((prevData) => ({
      ...prevData,
      players: prevData.players.filter((_, i) => i !== index),
    }));
  };

  const addAdmin = () => {
    if (formData.admins.length < 2) {
      setFormData((prevData) => ({
        ...prevData,
        admins: [...prevData.admins, ""],
      }));
    }
  };

  const removeAdmin = (index) => {
    setFormData((prevData) => ({
      ...prevData,
      admins: prevData.admins.filter((_, i) => i !== index),
    }));
  };

  const handleBack = () => {
    navigate(-1);
  };

  const validateForm = useCallback(() => {
    const newErrors = {};
    if (!isEditMode) {
      if (!formData.room) newErrors.room = "Room selection is required";

      // Validate numeric fields
      const numberFields = [
        { name: "min_buyin", label: "Minimum buy-in" },
        { name: "smallBlind", label: "Small Blind" },
        { name: "bigBlind", label: "Big Blind" },
      ];

      numberFields.forEach(({ name, label }) => {
        const value = Number(formData[name]);
        if (!formData[name] || isNaN(value) || value <= 0) {
          newErrors[name] = `${label} must be a valid number greater than 0`;
        }
      });

      if (!formData.gameType) newErrors.gameType = "Game type is required";
      if (!formData.currency)
        newErrors.currency = "Currency selection is required";
      if (!formData.type) newErrors.type = "Type is required";
      if (!formData.limit) newErrors.limit = "Limit is required";

      // Validate player data
      if (formData?.addPlayersNow && formData?.players?.length > 0) {
        const playerErrors = [];
        const minBuyIn = Number(formData.min_buyin);

        formData?.players?.forEach((player, index) => {
          const errors = {};
          const buyIn = Number(player.buyIn);

          if (!player?.username?.trim()) {
            errors.username = "Player name is required";
          }

          if (!player?.buyIn || isNaN(buyIn) || buyIn <= 0) {
            errors.buyIn = "Buy-in must be a valid number greater than 0";
          } else if (buyIn < minBuyIn) {
            errors.buyIn = `Buy-in must be at least ${formatDisplayValue(
              minBuyIn
            )}`;
          }

          if (Object.keys(errors).length > 0) {
            playerErrors[index] = errors;
          }
        });

        if (playerErrors?.length > 0) {
          newErrors.players = playerErrors;
        }

        // Check for duplicate player names
        const playerNames = formData?.players?.map((p) =>
          p?.username?.trim()?.toLowerCase()
        );
        const uniqueNames = new Set(playerNames);
        if (playerNames.length !== uniqueNames.size) {
          newErrors.players = [
            ...(newErrors.players || []),
            "Each player must have a unique name",
          ];
        }
      }
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  }, [formData, isEditMode]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setIsSubmitting(true);
    try {
      const filteredAdmins = formData?.admins?.filter(
        (admin) => admin.trim() !== ""
      );
      const filteredPlayers = formData?.players?.filter(
        (player) =>
          player?.username?.trim() !== "" && player?.buyIn?.trim() !== ""
      );

      const payload = isEditMode
        ? {
            admins: filteredAdmins,
            players_privacy: formData.playersPrivacy,
          }
        : {
            room_id: formData.room,
            min_buyin: Number(formData.min_buyin),
            small_blind: Number(formData.smallBlind),
            big_blind: Number(formData.bigBlind),
            game_type: formData.gameType,
            currency: formData.currency,
            currency_symbol: CURRENCIES[formData.currency],
            poker_type: formData.type,
            limit_type: formData.limit,
            players_privacy: formData.playersPrivacy,
            add_players_now: formData.addPlayersNow,
            admins: filteredAdmins,
            players: filteredPlayers?.map((player) => ({
              ...player,
              buyIn: Number(player.buyIn),
            })),
          };

      if (isEditMode) {
        await API.updateGameDetailsForGameId(
          gameId,
          payload,
          localStorage.getItem("token")
        );
        navigate(`/game-details/${gameId}`);
      } else {
        const {
          data: { game_id },
        } = await API.createGame(payload, localStorage.getItem("token"));
        showSnackbar("Game created successfully!", "success");
        navigate(`/game-details/${game_id}`);
      }
    } catch (error) {
      const message = error?.response?.data?.message;
      showSnackbar(message || "Failed to create game", "error");
    } finally {
      setIsSubmitting(false);
    }
  };

  const typeOptions = [
    { label: "No-Limit Hold'em (NLHE)", value: "NLHE" },
    { label: "Pot-Limit Omaha (PLO)", value: "PLO" },
  ];
  const gameOptions = [{ label: "Cash Game", value: "Cash Game" }];

  const currencyOptions = [
    {
      label: (
        <div className="flex gap-1 items-center align-middle">&#8377; INR</div>
      ),
      value: "INR",
    },
  ];

  if (loading && isEditMode) return <Loader context="gameDetails" />;
  if (error) return <div>{error}</div>;

  return (
    <form
      onSubmit={handleSubmit}
      className="flex flex-col min-h-screen bg-white p-4"
    >
      <div className="w-full max-w-md mx-auto space-y-6 mb-16">
        <button type="button" className="mb-4" onClick={handleBack}>
          <ChevronLeft size={24} />
        </button>

        <div className="flex flex-col items-center mb-6">
          <div className="rounded-[10px] border border-gray-200 bg-white shadow-sm p-3">
            <GameIcon />
          </div>
          <h1 className="text-2xl font-bold">
            {isEditMode ? "Edit Game" : "Create Game"}
          </h1>
        </div>

        <div className="space-y-6">
          <div className="space-y-4">
            <h2 className="text-lg font-semibold">Game Details</h2>

            <Input
              prefixIcon={<RoomIcon className="h-5 w-5" />}
              type="select"
              name="room"
              options={[
                ...roomOptions,
                !isEditMode && {
                  value: "create-new-room",
                  label: (
                    <Button
                      variant="link"
                      fullWidth
                      onClick={() => navigate("/create-new-room")}
                    >
                      + Create new room
                    </Button>
                  ),
                },
              ].filter(Boolean)}
              placeholder="Select Room"
              value={formData.room}
              onChange={(e) => handleSelectChange("room", e.target.value)}
              errorMessage={errors.room}
              disabled={isEditMode}
            />
            <Input
              type="select"
              name="gameType"
              options={gameOptions}
              placeholder="Select game"
              value={formData.gameType}
              onChange={(e) => handleSelectChange("gameType", e.target.value)}
              errorMessage={errors.gameType}
              disabled={isEditMode}
            />
            <Input
              type="select"
              name="type"
              options={typeOptions}
              placeholder="Select type"
              value={formData.type}
              onChange={(e) => handleSelectChange("type", e.target.value)}
              errorMessage={errors.type}
              disabled={isEditMode}
            />
            <div className="grid grid-cols-7 gap-4">
              <div className="col-span-2">
                <Input
                  type="select"
                  name="currency"
                  options={currencyOptions}
                  placeholder="Select currency"
                  value={formData.currency}
                  onChange={(e) =>
                    handleSelectChange("currency", e.target.value)
                  }
                  errorMessage={errors.currency}
                  disabled={isEditMode}
                />
              </div>
              <div className="col-span-5">
                <Input
                  type="text"
                  name="min_buyin"
                  placeholder="Minimum Buyin"
                  className="w-full p-3 border rounded-lg"
                  value={formatDisplayValue(formData.min_buyin)}
                  onChange={handleInputChange}
                  errorMessage={errors.min_buyin}
                  disabled={isEditMode}
                />
              </div>
            </div>

            <div className="flex space-x-4">
              <Input
                type="text"
                name="smallBlind"
                placeholder="Small Blind"
                className="p-3 border rounded-lg"
                value={formatDisplayValue(formData.smallBlind)}
                onChange={handleInputChange}
                errorMessage={errors.smallBlind}
                disabled={isEditMode}
              />
              <Input
                type="text"
                name="bigBlind"
                placeholder="Big Blind"
                className="p-3 border rounded-lg"
                value={formatDisplayValue(formData.bigBlind)}
                onChange={handleInputChange}
                errorMessage={errors.bigBlind}
                disabled={isEditMode}
              />
            </div>
          </div>
          <div className="space-y-4">
            <h2 className="text-lg font-semibold">
              Admins for this game(optional):
            </h2>
            {formData?.admins?.map((admin, index) => (
              <div key={index} className="flex space-x-2">
                <AutocompleteInput
                  placeholder="username"
                  className="flex-grow p-3 border rounded-lg"
                  value={admin?.toLowerCase()}
                  onChange={(e) => handleInputChange(e, index, "admins")}
                  disabled={isEditMode}
                />
                <button
                  type="button"
                  onClick={() => removeAdmin(index)}
                  className="text-red-500"
                >
                  <DeleteIcon />
                </button>
              </div>
            ))}
            {formData.admins.length < 2 && (
              <button
                type="button"
                className="group inline-flex flex-col items-start"
                onClick={addAdmin}
              >
                <span className="text-sm text-gray-900 underline underline-offset-4">
                  +Add more admins
                </span>
              </button>
            )}
          </div>
          <div className="space-y-4">
            {!isEditMode && (
              <div className="flex items-center justify-between">
                <span className="text-lg font-semibold">Add Players</span>
                <Toggle
                  toggleOn={formData.addPlayersNow}
                  toggleSwitch={() =>
                    setFormData((prev) => ({
                      ...prev,
                      addPlayersNow: !prev.addPlayersNow,
                    }))
                  }
                  disabled={isEditMode}
                />
              </div>
            )}

            {errors?.players && typeof errors?.players === "string" && (
              <small className="text-red-500 text-xs mt-1">
                {errors?.players}
              </small>
            )}

            {!isEditMode &&
              formData.addPlayersNow &&
              formData?.players?.map((player, index) => (
                <div key={index} className="flex flex-col space-y-2">
                  <div className="flex space-x-2">
                    <AutocompleteInput
                      placeholder="Player name"
                      value={player?.username}
                      onChange={(e) =>
                        handleInputChange(
                          {
                            target: {
                              name: "players.username",
                              value: e.target.value,
                            },
                          },
                          index,
                          "players"
                        )
                      }
                      disabled={isEditMode}
                      errorMessage={errors.players?.[index]?.username}
                      name={`players.username`}
                    />
                    <Input
                      type="text"
                      placeholder="Buy-in"
                      className="p-3 border rounded-lg"
                      value={formatDisplayValue(player.buyIn)}
                      onChange={(e) =>
                        handleInputChange(
                          {
                            target: {
                              name: "players.buyIn",
                              value: e.target.value,
                            },
                          },
                          index,
                          "players"
                        )
                      }
                      disabled={isEditMode}
                      errorMessage={errors.players?.[index]?.buyIn}
                    />
                    <button
                      type="button"
                      onClick={() => removePlayer(index)}
                      className="text-red-500"
                      disabled={isEditMode}
                    >
                      <DeleteIcon />
                    </button>
                  </div>
                  {errors.players?.[index]?.username && (
                    <p className="text-red-500 text-sm">
                      {errors.players[index].username}
                    </p>
                  )}
                  {errors.players?.[index]?.buyIn && (
                    <p className="text-red-500 text-sm">
                      {errors.players[index].buyIn}
                    </p>
                  )}
                </div>
              ))}
            {!isEditMode && formData.addPlayersNow && (
              <button
                type="button"
                className="group inline-flex flex-col items-start"
                onClick={addPlayer}
              >
                <span className="text-sm text-gray-900 underline underline-offset-4">
                  +Add more players
                </span>
              </button>
            )}
          </div>
          <Input
            type="checkbox"
            label="Keep players' details private"
            name="playersPrivacy"
            onChange={handleInputChange}
            value={formData.playersPrivacy}
          />
          <Button
            variant="primary"
            className="w-full my-8"
            type="submit"
            disabled={isSubmitting}
          >
            {isSubmitting
              ? isEditMode
                ? "Updating..."
                : "Creating..."
              : isEditMode
              ? "Update game"
              : "Create new game"}
          </Button>
        </div>
      </div>
    </form>
  );
};

export default CreateNewGame;
