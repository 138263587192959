import Button from "../../components/Button/Button";
import React, { useState } from "react";
import { X } from "lucide-react";
import Input from "../../components/Input/Input";
import { API } from "../../services";
import { ReactComponent as ChipOutIcon } from "../../assets/icons/chip-out.svg";
import { formatDisplayValue } from "../../utilities/commonFunction";

const ChipOutModal = ({
  isOpen,
  onClose,
  playerId,
  gameId,
  playerName,
  updateGameDetails,
}) => {
  const [value, setValue] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  if (!isOpen) return null;

  const validateForm = () => {
    setError("");
    if (!value || value < 0) {
      setError("Please enter a valid amount");
      return false;
    }
    return true;
  };

  const handleSubmit = async () => {
    if (validateForm()) {
      try {
        setLoading(true);
        const payload = {
          amount: Number(value),
        };

        const {
          data: { data },
        } = await API.chipOutPlayer(
          gameId,
          playerId,
          payload,
          localStorage.getItem("token")
        );

        updateGameDetails((prevGameData) => {
          const updatedPlayers = prevGameData.players.map((player) => {
            if (player.playerId === playerId) {
              return {
                ...player,
                chipOut: Number(data.chip_out_amount),
              };
            }
            return player;
          });
          return {
            ...prevGameData,
            players: updatedPlayers,
          };
        });

        handleClose();
      } catch (error) {
        setError(
          error?.response?.data?.message || "Failed to update chip-out amount"
        );
      } finally {
        setLoading(false);
      }
    }
  };

  const handleClose = () => {
    setValue("");
    setError("");
    onClose();
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg shadow-lg w-full max-w-md">
        <div className="p-4">
          <div className="flex justify-between items-center mb-4">
            <div className="p-3 rounded-lg border border-[#E4E7EC] bg-white shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)]">
              <ChipOutIcon size={24} />
            </div>
            <button
              onClick={handleClose}
              className="text-gray-400 hover:text-gray-600 transition-colors p-2"
              aria-label="Close modal"
            >
              <X size={24} />
            </button>
          </div>
          <h2 className="text-xl font-bold mb-2">Chip out {playerName}</h2>
          <p className="text-gray-600 mb-4">Please enter chip-out amount</p>

          <Input
            type="text"
            placeholder="e.g. 100, 1000, 10000"
            value={formatDisplayValue(value)}
            onChange={(e) => setValue(e.target.value.replace(/[^0-9]/g, ""))}
            name="value"
            errorMessage={error}
            className="w-full p-3 border rounded-lg"
          />

          <Button
            onClick={handleSubmit}
            variant="primary"
            className="w-full mt-8"
            disabled={loading}
          >
            {loading ? "Updating..." : "Confirm"}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default React.memo(ChipOutModal);
