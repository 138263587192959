import React, { useState } from "react";
import { X, MessageSquare, AlertCircle, CheckCircle2 } from "lucide-react";
import Button from "../../components/Button/Button";
import Input from "../../components/Input/Input";
import axios from "axios";
import { useSnackbar } from "../../context/SnackbarContext";
import { API } from "../../services";

const FeedbackModal = ({ isOpen, onClose }) => {
  const { showSnackbar } = useSnackbar();
  const [formData, setFormData] = useState({
    subject: "",
    message: "",
  });
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  if (!isOpen) return null;

  const validateForm = () => {
    const newErrors = {};
    if (!formData.subject.trim()) {
      newErrors.subject = "Subject is required";
    }
    if (!formData.message.trim()) {
      newErrors.message = "Message is required";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;

    setIsSubmitting(true);
    try {
      await API.addFeedback(formData, localStorage.getItem("token"));

      showSnackbar("Thank you for your feedback!", "success");

      onClose();
      setFormData({ subject: "", message: "" });
    } catch (error) {
      showSnackbar(
        error.response?.data?.message || "Failed to submit feedback",
        "error"
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
    if (errors[name]) {
      setErrors((prev) => ({
        ...prev,
        [name]: "",
      }));
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg shadow-lg w-full max-w-md">
        <div className="p-4">
          <div className="flex justify-between items-center mb-4">
            <div className="p-3 rounded-lg border border-[#E4E7EC] bg-white shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)]">
              <MessageSquare className="text-gray-600" size={24} />
            </div>
            <button
              onClick={onClose}
              className="text-gray-400 hover:text-gray-600 transition-colors p-2"
              aria-label="Close modal"
            >
              <X size={24} />
            </button>
          </div>

          <h2 className="text-2xl font-bold mb-2">Send feedback</h2>
          <p className="text-gray-600 mb-6">Help us improve your experience</p>

          <div className="space-y-4">
            <Input
              label="Subject"
              type="text"
              name="subject"
              value={formData.subject}
              onChange={handleChange}
              placeholder="Enter subject"
              required
              errorMessage={errors.subject}
              disabled={isSubmitting}
            />

            <Input
              label="Message"
              type="textarea"
              name="message"
              value={formData.message}
              onChange={handleChange}
              placeholder="Type your message here..."
              required
              rows={4}
              errorMessage={errors.message}
              disabled={isSubmitting}
            />

            <div className="grid gap-3">
              <Button
                onClick={handleSubmit}
                className="w-full"
                disabled={isSubmitting}
              >
                {isSubmitting ? "Submitting..." : "Submit Feedback"}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeedbackModal;
