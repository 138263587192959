import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Plus,
  DoorClosed,
  Gamepad2Icon,
} from "lucide-react";
import Button from "../Button/Button";

const FloatingActionButton = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="fixed bottom-20 right-4 z-50">
      {/* Sub-buttons container */}
      <div className="relative">
        {/* Create New Game Button */}

        <div
          className={`flex gap-3 items-center py-2 absolute bottom-0 right-0 mb-12 w-[max-content] transition-all duration-300 ${
            isOpen
              ? "opacity-100 translate-y-0"
              : "opacity-0 translate-y-10 pointer-events-none"
          }`}
          onClick={() => {
            navigate("/create-new-game");
            toggleMenu();
          }}
        >
          <span className="p-1 bg-gray-100 rounded-md text-sm">New Game</span>
          <Button variant="iconRoundOutline">
            <Gamepad2Icon size={24} />
          </Button>
        </div>
        <div
          className={`flex gap-3 items-center py-2 absolute bottom-0 right-0 mb-24 w-[max-content] transition-all duration-300 ${
            isOpen
              ? "opacity-100 translate-y-0"
              : "opacity-0 translate-y-10 pointer-events-none"
          }`}
          onClick={() => {
            navigate("/create-new-room");
            toggleMenu();
          }}
        >
          <span className="p-1 bg-gray-100 rounded-md text-sm">New Room</span>
          <Button variant="iconRoundOutline">
            <DoorClosed size={24} />
          </Button>
        </div>

        {/* Main FAB */}
        <Button
          onClick={toggleMenu}
          variant="iconRoundPrimary"
          className={`transition-transform duration-300 ${
            isOpen ? "rotate-45" : ""
          }`}
        >
          <Plus size={24} />
        </Button>
      </div>

      {/* Backdrop */}
      {isOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-20 transition-opacity duration-300"
          onClick={() => setIsOpen(false)}
          style={{ zIndex: -1 }}
        />
      )}
    </div>
  );
};

export default FloatingActionButton;
