import React, { useState, useEffect } from "react";
import { UserRoundPen, X } from "lucide-react";
import { API } from "../../services";
import Button from "../../components/Button/Button";
import Input from "../../components/Input/Input";
import { formatAmount, formatDisplayValue } from "../../utilities/commonFunction";

const EditDetailsModal = ({
  isOpen,
  onClose,
  gameId,
  playerId,
  playerName,
  initialBuyIn,
  initialChipOut,
  updateGameDetails,
  minBuyIn,
}) => {
  const [buyIn, setBuyIn] = useState("");
  const [chipOut, setChipOut] = useState("");
  const [errors, setErrors] = useState({ buyIn: "", chipOut: "" });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setBuyIn(initialBuyIn?.toString() || "");
      setChipOut(initialChipOut?.toString() || "");
      setErrors({ buyIn: "", chipOut: "" });
    }
  }, [isOpen, initialBuyIn, initialChipOut]);

  if (!isOpen) return null;

  const validateForm = () => {
    const newErrors = { buyIn: "", chipOut: "" };
    let isValid = true;

    const buyInAmount = Number(buyIn);
    if (!buyIn || isNaN(buyInAmount) || buyInAmount <= 0) {
      newErrors.buyIn = "Please enter a valid buy-in amount";
      isValid = false;
    } else if (buyInAmount < minBuyIn) {
      newErrors.buyIn = `Buy-in must be at least ${formatAmount(minBuyIn)}`;
      isValid = false;
    }

    const chipOutAmount = Number(chipOut);
    if (chipOut && (isNaN(chipOutAmount) || chipOutAmount < 0)) {
      newErrors.chipOut = "Chip-out amount must be greater than or equal to 0";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async () => {
    if (validateForm()) {
      try {
        setLoading(true);
        const payload = {
          buy_in: Number(buyIn),
          chip_out: chipOut ? Number(chipOut) : null,
        };

        const {
          data: { data },
        } = await API.updatePlayerDetails(
          gameId,
          playerId,
          payload,
          localStorage.getItem("token")
        );

        updateGameDetails((prevGameData) => {
          const updatedPlayers = prevGameData.players.map((player) => {
            if (player.playerId === playerId) {
              return {
                ...player,
                buyIn: Number(data.current_values.buy_in_amount),
                chipOut: data.current_values.cash_out_amount
                  ? Number(data.current_values.cash_out_amount)
                  : null,
              };
            }
            return player;
          });

          // Update stats
          const totalBuyIn = updatedPlayers.reduce(
            (sum, player) => sum + player.buyIn,
            0
          );
          const avgBuyIn = Math.round(totalBuyIn / updatedPlayers.length);

          return {
            ...prevGameData,
            players: updatedPlayers,
            stats: {
              ...prevGameData.stats,
              totalBuyIn,
              avgBuyIn,
            },
          };
        });

        onClose();
      } catch (error) {
        setErrors((prev) => ({
          ...prev,
          submit: error?.response?.data?.message || "Failed to update details",
        }));
      } finally {
        setLoading(false);
      }
    }
  };

  const handleInputChange = (e, setter) => {
    const value = e.target.value.replace(/[^0-9.]/g, "");
    setter(value);
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg shadow-lg w-full max-w-md">
        <div className="p-4">
          <div className="flex justify-between items-center mb-4">
            <div className="p-3 rounded-lg border border-[#E4E7EC] bg-white shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)]">
              <UserRoundPen stroke="#D700A1" size={24} />
            </div>
            <button
              onClick={onClose}
              className="text-gray-400 hover:text-gray-600 transition-colors p-2"
              aria-label="Close modal"
            >
              <X size={24} />
            </button>
          </div>

          <h2 className="text-xl font-bold mb-2">
            Edit details for {playerName}
          </h2>
          <p className="text-gray-600 mb-4">
            Please enter the following values
          </p>

          <div className="mb-4">
            <Input
              label="Buy In"
              type="text"
              placeholder={`Min buy-in: ${formatAmount(minBuyIn)}`}
              value={formatDisplayValue(buyIn)}
              onChange={(e) => {
                handleInputChange(e, setBuyIn);
                setErrors((prev) => ({ ...prev, buyIn: "" }));
              }}
              name="buyIn"
              className={`w-full border rounded-lg ${
                errors.buyIn ? "border-red-500" : "border-gray-300"
              }`}
            />
            {errors.buyIn && (
              <p className="text-red-500 text-sm mt-1">{errors.buyIn}</p>
            )}
          </div>

          <div className="mb-4">
            <Input
              label="Chip Out"
              type="text"
              placeholder="e.g. 100, 1000, 10000"
              value={formatDisplayValue(chipOut)}
              onChange={(e) => {
                handleInputChange(e, setChipOut);
                setErrors((prev) => ({ ...prev, chipOut: "" }));
              }}
              name="chipOut"
              className={`w-full border rounded-lg ${
                errors.chipOut ? "border-red-500" : "border-gray-300"
              }`}
            />
            {errors.chipOut && (
              <p className="text-red-500 text-sm mt-1">{errors.chipOut}</p>
            )}
          </div>

          {errors.submit && (
            <p className="text-red-500 text-sm mb-4">{errors.submit}</p>
          )}

          <Button
            onClick={handleSubmit}
            variant="primary"
            className="w-full"
            disabled={loading}
          >
            {loading ? "Updating..." : "Confirm"}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default React.memo(EditDetailsModal);
