import React, { useState } from "react";
import { X } from "lucide-react";
import { ReactComponent as AddPlayerIcon } from "../../assets/icons/add-player.svg";
import { ReactComponent as DeleteIcon } from "../../assets/icons/delete.svg";
import Button from "../../components/Button/Button";
import Input from "../../components/Input/Input";
import { API } from "../../services";
import AutocompleteInput from "../../components/AutoCompleteInput/AutoCompleteInput";

const handleNumberInput = (value) => {
  // Remove any non-numeric characters except decimal point
  const cleanValue = value.toString().replace(/[^0-9.]/g, "");

  // Handle empty input
  if (cleanValue === "") return "";

  // Ensure only one decimal point
  const parts = cleanValue.split(".");
  if (parts.length > 2) return parts[0] + "." + parts[1];

  // Convert to number and back to string to remove leading zeros
  const numberValue = Number(cleanValue);
  return isNaN(numberValue) ? "" : cleanValue;
};

// Utility function for formatting display values
const formatDisplayValue = (value) => {
  if (value === "" || value === null || value === undefined) return "";
  const number = Number(value);
  return isNaN(number) ? "" : number.toLocaleString();
};

const AddPlayersModal = ({
  isOpen,
  onClose,
  gameId,
  roomId,
  updateGameDetails,
  minBuyIn,
}) => {
  const [players, setPlayers] = useState([
    { username: "", buy_in_amount: minBuyIn || "" },
  ]);
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);

  if (!isOpen) return null;

  const handleInputChange = (e, index, field) => {
    const { value } = e.target;
    const newPlayers = [...players];

    // Handle buy-in amount differently from username
    if (field === "buy_in_amount") {
      const processedValue = handleNumberInput(value);
      newPlayers[index] = {
        ...newPlayers[index],
        [field]: processedValue,
      };
    } else if (field === "username") {
      newPlayers[index] = {
        ...newPlayers[index],
        [field]: value.toLowerCase(),
      };
    } else {
      newPlayers[index] = {
        ...newPlayers[index],
        [field]: value,
      };
    }

    setPlayers(newPlayers);

    // Clear error for this field if value is entered
    if (value && errors[index]) {
      const newErrors = [...errors];
      newErrors[index] = "";
      setErrors(newErrors);
    }
  };

  const addPlayer = () => {
    setPlayers([...players, { username: "", buy_in_amount: minBuyIn || "" }]);
  };

  const removePlayer = (index) => {
    if (players.length > 1) {
      const newPlayers = players.filter((_, idx) => idx !== index);
      const newErrors = errors.filter((_, idx) => idx !== index);
      setPlayers(newPlayers);
      setErrors(newErrors);
    }
  };

  const validateForm = () => {
    const newErrors = players.map((player, index) => {
      // Check for empty fields
      if (!player.username && !player.buy_in_amount)
        return "Both username and buy-in are required";
      if (!player.username) return "Username is required";
      if (!player.buy_in_amount) return "Buy-in amount is required";

      // Check for minimum buy-in
      const buyInAmount = Number(player.buy_in_amount);
      if (isNaN(buyInAmount) || buyInAmount <= 0) {
        return "Buy-in amount must be greater than 0";
      }
      if (buyInAmount < minBuyIn) {
        return `Buy-in must be at least ${formatDisplayValue(minBuyIn)}`;
      }

      // Check for duplicate usernames
      const isDuplicate = players.some(
        (p, i) =>
          i !== index &&
          p.username.toLowerCase() === player.username.toLowerCase()
      );
      if (isDuplicate) {
        return "Username must be unique";
      }

      return "";
    });

    setErrors(newErrors);
    return !newErrors.some((error) => error !== "");
  };

  const handleSubmit = async () => {
    if (validateForm()) {
      try {
        setLoading(true);
        // Convert buy_in_amount to number and format players data
        const formattedPlayers = players.map((player) => ({
          ...player,
          buy_in_amount: Number(player.buy_in_amount),
        }));

        const payload = {
          game_id: gameId,
          room_id: roomId,
          players: formattedPlayers,
        };

        const {
          data: { data },
        } = await API.addPlayerToGame(payload, localStorage.getItem("token"));

        // Update game details with new players
        updateGameDetails((prevGameData) => {
          const updatedPlayers = [
            ...prevGameData.players,
            ...data.map((player) => ({
              playerId: player.player_id,
              username: player.username,
              buyIn: player.buy_in_amount,
              chipOut: null,
            })),
          ];

          const totalBuyIn = updatedPlayers.reduce(
            (sum, player) => sum + player.buyIn,
            0
          );
          const playerCount = updatedPlayers.length;
          const avgBuyIn = Math.round(totalBuyIn / playerCount);

          return {
            ...prevGameData,
            players: updatedPlayers,
            stats: {
              ...prevGameData.stats,
              playerCount,
              totalBuyIn,
              avgBuyIn,
            },
          };
        });

        handleClose();
      } catch (error) {
        console.error("Error adding players:", error.message);
        setErrors(
          players.map(
            () => error.response?.data?.message || "Failed to add players"
          )
        );
      } finally {
        setLoading(false);
      }
    }
  };

  const handleClose = () => {
    setPlayers([{ username: "", buy_in_amount: minBuyIn }]);
    setErrors([]);
    onClose();
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg shadow-lg w-full max-w-md">
        <div className="p-4">
          <div className="flex justify-between items-center mb-4">
            <div className="p-3 rounded-lg border border-[#E4E7EC] bg-white shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)]">
              <AddPlayerIcon className="text-gray-600" size={24} />
            </div>
            <button
              onClick={handleClose}
              className="text-gray-400 hover:text-gray-600 transition-colors p-2"
              aria-label="Close modal"
            >
              <X size={24} />
            </button>
          </div>

          <h2 className="text-xl font-bold mb-6">Add players</h2>

          <div className="space-y-4 mb-4">
            {players.map((player, index) => (
              <div key={index} className="flex flex-col gap-3">
                <div className="flex gap-3 items-center">
                  <AutocompleteInput
                    value={player?.username?.toLowerCase()}
                    onChange={(e) => handleInputChange(e, index, "username")}
                    disabled={loading}
                  />
                  <Input
                    type="text"
                    placeholder={`Min buy-in: ${formatDisplayValue(minBuyIn)}`}
                    value={formatDisplayValue(player.buy_in_amount)}
                    onChange={(e) =>
                      handleInputChange(e, index, "buy_in_amount")
                    }
                  />
                  <button
                    type="button"
                    onClick={() => removePlayer(index)}
                    className="text-[#F04438] hover:text-[#D92D20]"
                    disabled={loading}
                  >
                    <DeleteIcon height={30} width={30} />
                  </button>
                </div>
                {errors[index] && (
                  <p className="text-red-500 text-sm">{errors[index]}</p>
                )}
              </div>
            ))}
          </div>

          <button
            type="button"
            className="group inline-flex flex-col items-start mb-6"
            onClick={addPlayer}
            disabled={loading}
          >
            <span className="text-sm text-gray-900 underline underline-offset-4">
              +Add more players
            </span>
          </button>

          <Button
            variant="primary"
            fullWidth
            onClick={handleSubmit}
            disabled={loading}
          >
            {loading ? "Adding Players..." : "Add Players"}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default React.memo(AddPlayersModal);
