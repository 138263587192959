import { CheckCircle, XCircle, X, AlertCircle, Info } from "lucide-react";
import React, { useEffect, useState } from "react";

const Snackbar = ({ message, type = "success", onClose }) => {
  const [mounted, setMounted] = useState(false);
  const [exiting, setExiting] = useState(false);

  useEffect(() => {
    // Mount animation
    const mountTimer = setTimeout(() => {
      setMounted(true);
    }, 10); // Small delay to ensure DOM is ready

    return () => clearTimeout(mountTimer);
  }, []);

  const handleClose = () => {
    setExiting(true);
    setTimeout(onClose, 300); // Match the duration in the CSS transition
  };

  const getIcon = () => {
    switch (type) {
      case "success":
        return <CheckCircle className="h-5 w-5 text-green-500" />;
      case "error":
        return <XCircle className="h-5 w-5 text-red-500" />;
      case "warning":
        return <AlertCircle className="h-5 w-5 text-yellow-500" />;
      case "info":
        return <Info className="h-5 w-5 text-blue-500" />;
      default:
        return null;
    }
  };

  const getBgColor = () => {
    switch (type) {
      case "success":
        return "bg-green-50";
      case "error":
        return "bg-red-50";
      case "warning":
        return "bg-yellow-50";
      case "info":
        return "bg-blue-50";
      default:
        return "bg-white";
    }
  };

  const getBorderColor = () => {
    switch (type) {
      case "success":
        return "border-green-100";
      case "error":
        return "border-red-100";
      case "warning":
        return "border-yellow-100";
      case "info":
        return "border-blue-100";
      default:
        return "border-gray-100";
    }
  };

  return (
    <div
      style={{
        transition: "all 300ms cubic-bezier(0.4, 0, 0.2, 1)",
      }}
      className={`
        pointer-events-auto min-w-[320px] shadow-lg rounded-lg border
        ${getBorderColor()} ${getBgColor()}
        transform transition-all duration-300
        ${mounted ? "translate-y-0 opacity-100" : "-translate-y-full opacity-0"}
        ${exiting ? "translate-y-[-100%] opacity-0" : ""}
      `}
    >
      <div className="flex items-center justify-between gap-2 p-4">
        <div className="flex items-center gap-3">
          {getIcon()}
          <p className="text-sm text-gray-700 font-medium">{message}</p>
        </div>
        <button
          onClick={handleClose}
          className="text-gray-400 hover:text-gray-600 transition-colors flex-shrink-0"
        >
          <X className="h-5 w-5" />
        </button>
      </div>
    </div>
  );
};

export default React.memo(Snackbar);
