import React from "react";
import { useNavigate, useLocation } from "react-router-dom";

const BottomNavigation = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const navItems = [
    {
      icon: (
        <svg
          width="22"
          height="21"
          viewBox="0 0 22 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="Group 11">
            <circle
              id="Ellipse 2"
              cx="11.0006"
              cy="10.3363"
              r="10.131"
              fill="none"
            />
            <path
              id="Subtract"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M11.0006 20.4673C16.5958 20.4673 21.1316 15.9315 21.1316 10.3363C21.1316 4.74113 16.5958 0.205322 11.0006 0.205322C5.40544 0.205322 0.869629 4.74113 0.869629 10.3363C0.869629 15.9315 5.40544 20.4673 11.0006 20.4673ZM11.0006 20.4673C12.4163 14.7843 15.0609 11.932 21.1316 10.3363C15.1045 8.61151 12.6539 6.47852 11.0006 0.205322C9.26147 6.50656 6.86502 8.47197 0.869629 10.3363C6.93501 12.3253 9.31157 14.5204 11.0006 20.4673Z"
              fill="#EE203D"
            />
          </g>
        </svg>
      ),
      label: "Home",
      path: "/home",
    },
    {
      icon: (
        <svg
          width="22"
          height="21"
          viewBox="0 0 22 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="Icon/Bold/Spade">
            <path
              id="Icon/Bold/Vector"
              d="M13.5335 18.5676H8.46804L9.15663 14.911C8.52911 15.2471 7.82526 15.415 7.11358 15.3985C6.40191 15.3819 5.70664 15.1814 5.09544 14.8164C4.48424 14.4515 3.97794 13.9345 3.62579 13.3158C3.27365 12.6971 3.08767 11.9978 3.08594 11.286C3.08594 7.17024 11.0008 2.10474 11.0008 2.10474C11.0008 2.10474 18.9156 7.17024 18.9156 11.286C18.9139 11.9978 18.7279 12.6971 18.3758 13.3158C18.0236 13.9345 17.5173 14.4515 16.9061 14.8164C16.2949 15.1814 15.5997 15.3819 14.888 15.3985C14.1763 15.415 13.4725 15.2471 12.845 14.911L13.5335 18.5676Z"
              stroke="currentColor"
              strokeWidth="1.55419"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </svg>
      ),
      label: "Games",
      path: "/games",
    },
    {
      icon: (
        <svg
          width="21"
          height="22"
          viewBox="0 0 21 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M18.3335 8.66667L10.8335 2.66667L3.3335 8.66667V18C3.3335 18.5304 3.54421 19.0391 3.91928 19.4142C4.29436 19.7893 4.80306 20 5.3335 20H16.3335C16.8639 20 17.3726 19.7893 17.7477 19.4142C18.1228 19.0391 18.3335 18.5304 18.3335 18V8.66667Z"
            stroke="currentColor"
            strokeWidth="1.55419"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M8.3335 20V12H13.3335V20"
            stroke="currentColor"
            strokeWidth="1.55419"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
      label: "Rooms",
      path: "/rooms",
    },
    {
      icon: (
        <svg
          width="21"
          height="22"
          viewBox="0 0 21 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="Icon/Bold/User">
            <path
              id="Icon/Bold/Vector"
              d="M10.6334 13.5022C13.431 13.5022 15.6989 11.2343 15.6989 8.43666C15.6989 5.63906 13.431 3.37115 10.6334 3.37115C7.83577 3.37115 5.56787 5.63906 5.56787 8.43666C5.56787 11.2343 7.83577 13.5022 10.6334 13.5022Z"
              stroke="currentColor"
              strokeWidth="1.55419"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              id="Icon/Bold/Vector_2"
              d="M2.95605 17.9345C3.73403 16.5867 4.85311 15.4675 6.20079 14.6893C7.54847 13.9111 9.07725 13.5015 10.6335 13.5015C12.1897 13.5015 13.7185 13.9111 15.0661 14.6893C16.4138 15.4675 17.5329 16.5867 18.3109 17.9345"
              stroke="currentColor"
              strokeWidth="1.55419"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </svg>
      ),
      label: "Profile",
      path: "/profile",
    },
  ];

  return (
    <nav className="fixed bottom-0 left-0 right-0 bg-white border-t border-gray-200 w-[100vw]">
      <div className="flex justify-around items-center h-16">
        {navItems.map((item, index) => {
          const isActive = location.pathname === item.path;
          return (
            <button
              key={index}
              className={`flex flex-col items-center justify-center w-full h-full ${
                isActive ? "text-gray-800" : "text-gray-500"
              }`}
              onClick={() => navigate(item.path)}
            >
              {item.icon}
              <span
                className={`text-xs mt-1 ${
                  isActive ? "font-semibold" : "font-normal"
                } ${
                  item?.label === "Home" ? "text-[#EE203D]" : "text-inherit"
                }`}
              >
                {item.label}
              </span>
            </button>
          );
        })}
      </div>
    </nav>
  );
};

export default BottomNavigation;