import React, { useState } from "react";
import { X, LogOut } from "lucide-react";
import Button from "../../components/Button/Button";
import { useNavigate } from "react-router";
import { API } from "../../services";
import { useSnackbar } from "../../context/SnackbarContext";
import { useAuth } from "../../services/hooks/useAuth";

const LogoutModal = ({ isOpen, onClose }) => {
  const navigate = useNavigate();
  const { logout } = useAuth();
  const { showSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);

  if (!isOpen) return null;

  const handleLogout = async () => {
    setIsLoading(true);
    try {
      // Get tokens before logout
      const token = localStorage.getItem("token");
      const googleToken = localStorage.getItem("googleToken");

      // Call API first
      await API.logout(token, googleToken);

      // Then use auth context to handle logout
      await logout();

      showSnackbar("Logged out successfully", "success");
      navigate("/login");
    } catch (error) {
      console.error("Logout error:", error);
      showSnackbar("Failed to logout. Please try again.", "error");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg shadow-lg w-full max-w-md">
        <div className="p-4">
          <div className="flex justify-between items-center mb-4">
            <div className="p-3 rounded-lg border border-[#E4E7EC] bg-white shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)]">
              <LogOut className="text-gray-600" size={24} />
            </div>
            <button
              onClick={onClose}
              className="text-gray-400 hover:text-gray-600 transition-colors p-2"
              aria-label="Close modal"
              disabled={isLoading}
            >
              <X size={24} />
            </button>
          </div>

          <h2 className="text-xl font-bold mb-2">Logout confirmation</h2>
          <p className="text-gray-600 mb-6">Are you sure you want to logout?</p>

          <div className="flex gap-4 w-full">
            <Button
              onClick={onClose}
              variant="outline"
              className="w-full"
              disabled={isLoading}
            >
              Cancel
            </Button>
            <Button
              onClick={handleLogout}
              variant="dangerFilled"
              className="w-full"
              loading={isLoading}
              disabled={isLoading}
            >
              {isLoading ? "Logging out..." : "Logout"}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogoutModal;
