import React, { useCallback, useEffect, useState } from "react";
import Table from "../../components/Table/Table";
import {
  ChevronLeft,
  UserRoundPlus,
  Settings,
  UserRoundPen,
  AlertCircle,
  Lock,
  DollarSignIcon,
  HistoryIcon,
  Trash2,
  RefreshCw,
} from "lucide-react";
import TopUpModal from "./TopUpModal";
import ChipOutModal from "./ChipOutModal";
import EditDetailsModal from "./EditDetailsModal";
import DeleteModal from "./DeleteModal";
import ViewHistoryModal from "./ViewHistoryModal";
import Button from "../../components/Button/Button";
// import { ReactComponent as DollarIcon } from "../../assets/icons/dollar.svg";
import { ReactComponent as ChipOutIcon } from "../../assets/icons/chip-out.svg";
// import { ReactComponent as HistoryIcon } from "../../assets/icons/history.svg";
// import { ReactComponent as Trash2 } from "../../assets/icons/delete.svg";

import { useNavigate, useParams } from "react-router-dom";
import { formatAmount, formatDateTime } from "../../utilities/commonFunction";
import AddPlayersModal from "./AddPlayersModal";
import DeleteGameModal from "./DeleteGameModal";
import EndGameModal from "./EndGameModal";
import { API } from "../../services";
import { useSnackbar } from "../../context/SnackbarContext";
import Loader from "../../components/Loader/Loader";

function GameDetails() {
  const navigate = useNavigate();
  const { gameId } = useParams();
  const { showSnackbar } = useSnackbar();

  const [activePlayer, setActivePlayer] = useState(null);
  const [mismatch, setMismatch] = useState(0);
  const [isTopUpModalOpen, setIsTopUpModalOpen] = useState(false);
  const [isEndGameModalOpen, setIsEndGameModalOpen] = useState(false);
  const [isChipOutModalOpen, setIsChipOutModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isAddPlayerModalOpen, setIsAddPlayerModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isDeleteGameModalOpen, setIsDeleteGameModalOpen] = useState(false);
  const [isHistoryModalOpen, setIsHistoryModalOpen] = useState(false);
  const [isSettingsMenuOpen, setIsSettingsMenuOpen] = useState(false);
  const [gameData, setGameData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const REFRESH_INTERVAL = 15 * 60 * 1000;

  const fetchGameDetails = useCallback(
    async (showLoadingState = true) => {
      try {
        if (showLoadingState) {
          setLoading(true);
        } else {
          setIsRefreshing(true);
        }

        const {
          data: { data },
        } = await API.getGameDetailsFromGameId(
          gameId,
          localStorage.getItem("token")
        );
        setGameData(data);
        setError(null);
      } catch (err) {
        setError(
          err.response?.data?.error ||
            "Failed to fetch game details. Please try again later."
        );
        showSnackbar("Failed to refresh game details", "error");
      } finally {
        setLoading(false);
        setIsRefreshing(false);
      }
    },
    [gameId, showSnackbar]
  );

  const handleRefresh = useCallback(() => {
    fetchGameDetails(false);
  }, [fetchGameDetails]);

  useEffect(() => {
    if (gameId) {
      fetchGameDetails(true);

      if (gameData?.status === "active") {
        const intervalId = setInterval(() => {
          fetchGameDetails(false);
        }, REFRESH_INTERVAL);

        return () => clearInterval(intervalId);
      }
    }
  }, [gameId, gameData?.status, fetchGameDetails]);


  const columns = [
    {
      key: "username",
      header: "PLAYER",
    },
    {
      key: "buyIn",
      header: "BUY IN",
      render: (row) => (
        <span
          className="font-semibold text-[16px]"
          style={{ fontFamily: '"Bricolage Grotesque", sans-serif' }}
        >
          {formatAmount(row.buyIn)}
        </span>
      ),
    },
    {
      key: "chipOut",
      header: "CHIP OUT",
      render: (row) => (
        <span
          className="font-semibold text-[16px]"
          style={{ fontFamily: '"Bricolage Grotesque", sans-serif' }}
        >
          {formatAmount(row.chipOut)}
        </span>
      ),
    },
  ];

  const actionButtons =
    (gameData?.isAdmin || gameData?.isHost) && gameData?.status === "active"
      ? [
          {
            icon: <DollarSignIcon size={18} />,
            className: "bg-gray-200 text-gray-600",
            isDisabled: (row) => row?.chipOut !== null,
            onClick: (row) => {
              if (row?.chipOut !== null) {
                showSnackbar("Chip out already done for the player.", "info");
              } else {
                setActivePlayer(JSON.parse(JSON.stringify(row, null, 2)));
                setIsTopUpModalOpen(true);
              }
            },
          },
        ]
      : [
          {
            className: "bg-gray-200 text-gray-600",
            icon: <HistoryIcon size={18} stroke="#21468B" />,
            onClick: (row) => {
              setActivePlayer(JSON.parse(JSON.stringify(row, null, 2)));
              setIsHistoryModalOpen(true);
            },
          },
        ];

  const ActionButton = ({ icon: icon, color, onClick, disabled }) => (
    <Button
      variant="iconRound"
      icon={icon}
      onClick={onClick}
      disabled={disabled}
    />
  );
  const expandableContent = (row) => (
    <div className="flex justify-end space-x-2 py-2">
      <ActionButton
        icon={<UserRoundPen size={18} stroke="#D700A1" />}
        onClick={() => {
          setActivePlayer(row);
          setIsEditModalOpen(true);
        }}
      />
      <ActionButton
        icon={<ChipOutIcon />}
        disabled={row?.chipOut !== null}
        onClick={() => {
          if (row?.chipOut !== null) {
            showSnackbar("Chip out already done for the player.", "info");
          } else {
            setActivePlayer(row);
            setIsChipOutModalOpen(true);
          }
        }}
      />
      <ActionButton
        icon={<HistoryIcon size={18} stroke="#21468B" />}
        onClick={() => {
          setActivePlayer(row);
          setIsHistoryModalOpen(true);
        }}
      />
      <ActionButton
        icon={<Trash2 size={18} className="text-red-500" />}
        onClick={() => {
          setActivePlayer(row);
          setIsDeleteModalOpen(true);
        }}
      />
    </div>
  );

  const updateGameDetails = (updateValueFunc) => {
    setGameData(updateValueFunc);
  };
  const handleBack = () => {
    navigate(-1);
  };

  const handleEndGame = () => {
    if (
      gameData?.players?.findIndex((player) => player.chipOut === null) === -1
    ) {
      const { totalBuyIn, totalChipOut } = gameData?.players?.reduce(
        (acc, curr) => ({
          ...acc,
          totalBuyIn: acc?.totalBuyIn + curr?.buyIn,
          totalChipOut: acc?.totalChipOut + curr?.chipOut,
        }),
        { totalBuyIn: 0, totalChipOut: 0 }
      );
      if (totalChipOut !== totalBuyIn) {
        setMismatch(totalChipOut - totalBuyIn);
      } else {
        setMismatch(0);
        // showSnackbar("proceed to settlement", "success");
      }
      setIsEndGameModalOpen(true);
    } else {
      showSnackbar("Chip-out for one or more players pending.", "error");
    }
  };

  if (loading) {
    return <Loader context="gameDetails" />;
  }

  return (
    <div className="max-w-3xl mx-auto p-4 pt-0 flex flex-col gap-2 h-[calc(100%-5rem)] overflow-auto">
      <header className="flex items-center mb-4 pt-4 sticky top-0 z-20 bg-white">
        <button onClick={handleBack} className="py-2">
          <ChevronLeft size={24} />
        </button>
        <h1 className="text-md font-bold w-full text-center">Game</h1>
        {gameData?.status === "active" && (
          <Button
            variant="ghost"
            className="py-2"
            onClick={handleRefresh}
            disabled={isRefreshing || loading}
          >
            <RefreshCw
              size={20}
              className={`${isRefreshing ? "animate-spin" : ""}`}
            />
          </Button>
        )}
      </header>

      <div className="mb-6">
        <div className="flex justify-between items-center gap-4">
          <h2
            className="text-[22px] font-semibold leading-[130%] tracking-[-0.64px] text-[#1A1C1E]"
            style={{ fontFamily: '"Bricolage Grotesque", sans-serif' }}
          >
            {`${gameData?.type} - ${gameData?.gameType} - ${gameData?.roomName}`}
          </h2>
          {(gameData?.isHost || gameData?.isAdmin) &&
            gameData?.status === "active" && (
              <div className="flex items-center justify-end gap-2 relative">
                <Button
                  variant="secondary"
                  icon={<UserRoundPlus size={24} strokeWidth={2.3} />}
                  onClick={() => {
                    setIsAddPlayerModalOpen(true);
                  }}
                />
                {gameData?.isHost && (
                  <Button
                    variant="secondary"
                    icon={<Settings size={24} strokeWidth={2.3} />}
                    onClick={() => {
                      setIsSettingsMenuOpen((prev) => !prev);
                    }}
                  />
                )}
                {isSettingsMenuOpen && gameData?.isHost && (
                  <div className="absolute top-8 right-0 bg-white shadow-lg rounded-md p-1 z-2 text-right flex flex-col w-max">
                    <button
                      className=" p-1 hover:bg-gray-100 rounded-md text-right"
                      onClick={() => {
                        navigate(`/edit-game/${gameId}`);
                      }}
                    >
                      Edit game
                    </button>
                    <button
                      className="p-1 hover:bg-gray-100 rounded-md text-right"
                      onClick={() => {
                        setIsDeleteGameModalOpen(true);
                        setIsSettingsMenuOpen(false);
                      }}
                    >
                      Delete game
                    </button>
                    {/* Other settings options can be added here */}
                  </div>
                )}
              </div>
            )}
        </div>
      </div>

      <div className="grid grid-cols-7 gap-2 mb-6">
        <div className="col-span-2">
          <p className="text-[10px] text-gray-500">START TIME</p>
          <p
            className="font-semibold text-md"
            style={{ fontFamily: '"Bricolage Grotesque", sans-serif' }}
          >
            {formatDateTime(gameData?.stats?.startTime)}
          </p>
        </div>
        <div className="col-span-1">
          <p className="text-[10px] text-gray-500">PLAYERS</p>
          <p
            className="font-semibold text-lg"
            style={{ fontFamily: '"Bricolage Grotesque", sans-serif' }}
          >
            {gameData?.stats?.playerCount}
          </p>
        </div>
        <div className="col-span-2">
          <p className="text-[10px] text-gray-500">AVG BUY-IN</p>
          <p
            className="font-semibold text-lg"
            style={{ fontFamily: '"Bricolage Grotesque", sans-serif' }}
          >
            {gameData?.stats?.avgBuyIn
              ? `${gameData?.currency_symbol}${formatAmount(
                  gameData?.stats?.avgBuyIn
                )}`
              : "-"}
          </p>
        </div>
        <div className="col-span-2">
          <p className="text-[10px] text-gray-500">TOTAL BUY-IN</p>
          <p
            className="font-semibold text-lg"
            style={{ fontFamily: '"Bricolage Grotesque", sans-serif' }}
          >
            {gameData?.stats?.totalBuyIn
              ? `${gameData?.currency_symbol}${formatAmount(
                  gameData?.stats?.totalBuyIn
                )}`
              : "-"}
          </p>
        </div>
      </div>
      <Table
        columns={columns}
        data={gameData?.players}
        actionButtons={actionButtons}
        expandableContent={
          (gameData?.isAdmin || gameData?.isHost) &&
          gameData?.status === "active" &&
          expandableContent
        }
        customStyleClasses="max-h-[100%] flex-grow"
      />

      <div
        className={`mt-6 ${
          !(gameData?.isHost || gameData?.isAdmin) &&
          (gameData?.playersPrivacy || gameData?.status === "active")
            ? "w-full"
            : "grid grid-cols-2 gap-4"
        }`}
      >
        {(gameData?.isHost ||
          gameData?.isAdmin ||
          !gameData?.playersPrivacy) && (
          <Button
            variant="outline"
            fullWidth
            onClick={() => setIsHistoryModalOpen(true)}
          >
            VIEW ACTIVITY LOG
          </Button>
        )}
        {(gameData?.isHost || gameData?.isAdmin) &&
          gameData?.status === "active" && (
            <Button variant="danger" fullWidth onClick={handleEndGame}>
              END GAME
            </Button>
          )}
        {gameData?.status === "completed" && (
          <Button
            variant="outline"
            fullWidth
            onClick={() => navigate(`/settlement-details/${gameId}`)}
          >
            SHOW SETTLEMENT
          </Button>
        )}
      </div>

      <TopUpModal
        updateGameDetails={updateGameDetails}
        isOpen={isTopUpModalOpen}
        onClose={() => {
          setIsTopUpModalOpen(false);
          setActivePlayer(null);
        }}
        playerName={activePlayer?.username}
        playerId={activePlayer?.playerId}
        initialBuyIn={activePlayer?.buyIn}
        gameId={gameId}
        roomId={gameData?.roomId}
        minBuyIn={gameData?.minBuyIn}
      />
      <ChipOutModal
        updateGameDetails={updateGameDetails}
        isOpen={isChipOutModalOpen}
        onClose={() => {
          setIsChipOutModalOpen(false);
          setActivePlayer(null);
        }}
        playerName={activePlayer?.username}
        playerId={activePlayer?.playerId}
        gameId={gameId}
      />
      <EditDetailsModal
        updateGameDetails={updateGameDetails}
        isOpen={isEditModalOpen}
        onClose={() => {
          setIsEditModalOpen(false);
          setActivePlayer(null);
        }}
        gameId={gameId}
        playerName={activePlayer?.username}
        playerId={activePlayer?.playerId}
        initialBuyIn={activePlayer?.buyIn}
        initialChipOut={activePlayer?.chipOut}
        minBuyIn={gameData?.minBuyIn}
      />
      <DeleteModal
        updateGameDetails={updateGameDetails}
        isOpen={isDeleteModalOpen}
        onClose={() => {
          setIsDeleteModalOpen(false);
          setActivePlayer(null);
        }}
        gameId={gameId}
        playerName={activePlayer?.username}
        playerId={activePlayer?.playerId}
      />
      <DeleteGameModal
        updateGameDetails={updateGameDetails}
        isOpen={isDeleteGameModalOpen}
        onClose={() => {
          setIsDeleteGameModalOpen(false);
        }}
        gameId={gameId}
      />
      <ViewHistoryModal
        updateGameDetails={updateGameDetails}
        isOpen={isHistoryModalOpen}
        onClose={() => {
          setIsHistoryModalOpen(false);
          setActivePlayer(null);
        }}
        gameId={gameId}
        playerName={activePlayer?.username}
        playerId={activePlayer?.playerId}
      />
      <AddPlayersModal
        updateGameDetails={updateGameDetails}
        isOpen={isAddPlayerModalOpen}
        onClose={() => setIsAddPlayerModalOpen(false)}
        gameId={gameId}
        roomId={gameData?.roomId}
        minBuyIn={gameData?.minBuyIn}
      />
      <EndGameModal
        currencySymbol={gameData?.currency_symbol}
        updateGameDetails={updateGameDetails}
        isOpen={isEndGameModalOpen}
        onClose={() => setIsEndGameModalOpen(false)}
        gameId={gameId}
        mismatchAmount={mismatch}
      />
    </div>
  );
}

export default GameDetails;
