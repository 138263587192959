import React, { useState, useCallback, useEffect } from "react";
import { ChevronLeft } from "lucide-react";
import { ReactComponent as RoomIcon } from "../../assets/icons/create-room.svg";
import { ReactComponent as DeleteIcon } from "../../assets/icons/delete-list-item.svg";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../components/Button/Button";
import axios from "axios";
import Input from "../../components/Input/Input";
import { useSnackbar } from "../../context/SnackbarContext";
import DeleteRoomModal from "./DeleteRoomModal";
import { API } from "../../services";
import Loader from "../../components/Loader/Loader";

const EditRoom = () => {
  const navigate = useNavigate();
  const { roomId } = useParams();
  const { showSnackbar } = useSnackbar();

  const [roomName, setRoomName] = useState("");
  const [roomLocation, setRoomLocation] = useState("");
  const [description, setDescription] = useState("");
  const [admins, setAdmins] = useState([""]);
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    const fetchRoomDetails = async () => {
      try {
        const { data } = await API.getRoomDetailsFromRoomId(
          roomId,
          localStorage.getItem("token")
        );

        setRoomName(data.room_name);
        setRoomLocation(data.room_location);
        setDescription(data.description || "");
        setAdmins(
          data.admin_details?.length
            ? data?.admin_details?.map((admin) => admin?.username)
            : [""]
        );
        setIsLoading(false);
      } catch (error) {
        const message = error?.response?.data?.error;
        showSnackbar(message || "Failed to fetch room details", "error");
        navigate(-1);
      }
    };

    fetchRoomDetails();
  }, [roomId, navigate, showSnackbar]);

  const handleAdminChange = (index, value) => {
    const newAdmins = [...admins];
    newAdmins[index] = value;
    setAdmins(newAdmins);
  };

  const handleRemoveAdmin = (index) => {
    const newAdmins = admins.filter((_, i) => i !== index);
    setAdmins(newAdmins);
  };

  const handleBack = () => {
    navigate(-1);
  };

  const handleAddAdmin = () => {
    if (admins.length < 2) {
      setAdmins([...admins, ""]);
    }
  };

  const validateForm = useCallback(() => {
    const newErrors = {};
    if (!roomLocation.trim()) {
      newErrors.roomLocation = "Room location is required";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  }, [roomLocation]);

  const handleSubmit = async () => {
    if (!validateForm()) return;

    setIsSubmitting(true);
    try {
      const filteredAdmins = admins.filter((admin) => admin.trim() !== "");
      const payload = {
        room_location: roomLocation,
        admins: filteredAdmins,
        description,
      };

      const {
        data: { message },
      } = await API.updateRoomDetailsFromRoomId(
        roomId,
        payload,
        localStorage.getItem("token")
      );

      showSnackbar(message || "Room updated successfully", "success");
      navigate(-1);
    } catch (error) {
      const message = error?.response?.data?.error;
      showSnackbar(
        message || "Failed to update room. Please try again.",
        "error"
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleDeleteRoom = async () => {
    setIsDeleting(true);
    try {
      const {
        data: { message },
      } = await API.deleteRoom(roomId, localStorage.getItem("token"));

      showSnackbar(message || "Room deleted successfully", "success");
      navigate("/rooms");
    } catch (error) {
      const message = error?.response?.data?.error;
      showSnackbar(
        message || "Failed to delete room. Please try again.",
        "error"
      );
    } finally {
      setIsDeleting(false);
      setShowDeleteDialog(false);
    }
  };

  if (isLoading) {
    return (
      <Loader context="roomDetails"/>
    );
  }

  return (
    <div className="flex flex-col items-center min-h-screen bg-white p-4">
      <div className="w-full max-w-md space-y-8">
        <div className="space-y-9">
          <div className="flex flex-col gap-8">
            <button onClick={handleBack}>
              <ChevronLeft size={24} />
            </button>
            <div className="flex flex-col gap-3 items-center">
              <div className="rounded-[10px] border border-gray-200 bg-white shadow-sm p-3">
                <RoomIcon />
              </div>
              <h2
                className="text-center text-[24px] font-semibold leading-[130%] tracking-[-0.64px] text-[#1A1C1E]"
                style={{ fontFamily: '"Bricolage Grotesque", sans-serif' }}
              >
                Edit Room
              </h2>
            </div>
          </div>

          <div className="flex flex-col gap-6">
            <div>
              <h2 className="text-md mb-4">Room Information</h2>
              <Input
                type="text"
                value={roomName}
                disabled
                className="w-full p-3 border rounded-lg mb-2 bg-gray-100"
              />
              <Input
                type="text"
                placeholder="Room Location, eg. Las Vegas..."
                value={roomLocation}
                onChange={(e) => {
                  setRoomLocation(e.target.value);
                  setErrors((prev) => ({ ...prev, [e.target.name]: "" }));
                }}
                name="roomLocation"
                className={`w-full p-3 border rounded-lg ${
                  errors.roomLocation ? "border-red-500" : "mb-2"
                }`}
              />
              {errors.roomLocation && (
                <p className="text-red-500 text-sm mb-2">
                  {errors.roomLocation}
                </p>
              )}
              <Input
                type="textarea"
                placeholder="Room Description (Optional)"
                value={description}
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
                name="description"
                className="w-full p-3 border rounded-lg h-24"
              />
            </div>

            <div>
              <h2 className="text-md mb-4">Admins (Optional)</h2>
              {admins.map((admin, index) => (
                <div key={index} className="flex mb-2">
                  <Input
                    type="text"
                    placeholder="Username"
                    value={admin}
                    onChange={(e) => handleAdminChange(index, e.target.value)}
                  />
                  <button
                    onClick={() => handleRemoveAdmin(index)}
                    className="ml-2 p-2 text-red-500"
                  >
                    <DeleteIcon />
                  </button>
                </div>
              ))}
              {admins.length < 2 && (
                <button
                  className="group inline-flex flex-col items-start"
                  onClick={handleAddAdmin}
                >
                  <span className="text-sm text-gray-900 underline underline-offset-4">
                    +Add more admins
                  </span>
                </button>
              )}
            </div>

            <Button
              variant="primary"
              className="w-full"
              onClick={handleSubmit}
              disabled={isSubmitting}
            >
              {isSubmitting ? "Updating..." : "Update room"}
            </Button>

            <Button
              variant="danger"
              onClick={() => setShowDeleteDialog(true)}
              disabled={isDeleting}
            >
              {isDeleting ? "Deleting..." : "Delete room"}
            </Button>
          </div>
        </div>
      </div>

      <DeleteRoomModal
        isOpen={showDeleteDialog}
        onClose={() => setShowDeleteDialog(false)}
        onConfirm={handleDeleteRoom}
        isDeleting={isDeleting}
      />
    </div>
  );
};

export default EditRoom;
