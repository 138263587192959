import React, { useState, useEffect } from "react";
import { Plus } from "lucide-react";
import Button from "../../components/Button/Button";
import GameEntry from "../../components/GameEntry/GameEntry";
import { useNavigate } from "react-router-dom";
import { API } from "../../services";
import Loader from "../../components/Loader/Loader";
import { formatAmount } from "../../utilities/commonFunction";

const Home = () => {
  const navigate = useNavigate();
  const [dashboardData, setDashboardData] = useState({
    summary: {
      totalPnL: 0,
      totalGames: 0,
    },
    activeGames: [],
    recentGames: [],
  });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        const {
          data: { data },
        } = await API.getDashboardData(localStorage.getItem("token"));

        // Transform API data to match component's expected format
        const transformedData = {
          totalWinnings: data?.summary.totalPnL,
          gamesPlayed: data?.summary.totalGames,
          activeGames: data?.activeGames.map((game) => ({
            gameId: game.id,
            type: game.title,
            venue: game.location,
            date: game.date,
            players: game.playerCount,
            buyIn: formatAmount(game.totalBuyIn),
            isActive: true,
            venue: game?.roomName,
            playersPrivacy: game?.playersPrivacy,
            isHost: game?.isHost,
            isAdmin: game?.isAdmin,
            currency: game?.currency,
          })),
          lastGames: data?.recentGames.map((game) => ({
            gameId: game.id,
            amount: game.pnl,
            type: game.title,
            venue: game.location,
            date: game.date,
            duration: `${game.duration}`,
            players: game.playerCount,
            isActive: false,
            venue: game?.roomName,
            playersPrivacy: game?.playersPrivacy,
            isHost: game?.isHost,
            isAdmin: game?.isAdmin,
            currency: game?.currency,
          })),
        };

        setDashboardData(transformedData);
        setIsLoading(false);
      } catch (err) {
        console.error("Error fetching dashboard data:", err);
        setError(
          err.response?.data?.message || "Failed to load dashboard data"
        );
        setIsLoading(false);
      }
    };

    fetchDashboardData();
  }, []);

  if (isLoading) {
    return <Loader context="home" />;
  }

  if (error) {
    return (
      <div className="max-w-3xl mx-auto p-4 text-center">
        <div className="bg-red-50 text-red-600 p-4 rounded-lg">
          <p className="font-medium">{error}</p>
          <button
            onClick={() => window.location.reload()}
            className="mt-2 text-sm text-red-700 hover:text-red-800 underline"
          >
            Try Again
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-3xl mx-auto flex flex-col gap-2 h-[calc(100%-4rem)] overflow-auto">
      {/* Top Purple Section */}
      <div
        className={`relative flex flex-col gap-3 items-center pt-10 ${
          dashboardData?.totalWinnings >= 0 ? "bg-[#F5FDF7]" : "bg-[#FFEEEE]"
        }`}
      >
        {/* Bankroll Display */}
        <div className="text-center flex flex-col gap-1 items-center">
          <p className="text-lg font-semibold text-[#344054]">TOTAL PnL</p>
          <h1
            className="text-5xl font-bold flex items-center justify-center"
            style={{ fontFamily: '"Bricolage Grotesque", sans-serif' }}
          >
            {dashboardData?.totalWinnings < 0 && "- "}
            &#8377;
            {formatAmount(Math.abs(dashboardData.totalWinnings))}
          </h1>
          <p
            className="text-xl text-[#4b5563]"
            style={{ fontFamily: '"Bricolage Grotesque", sans-serif' }}
          >
            IN{" "}
            <span className="font-semibold text-[#191D23]">
              {dashboardData.gamesPlayed}{" "}
            </span>
            GAMES
          </p>
        </div>

        <div className="h-4"></div>

        {/* Action Buttons */}
        <div className="absolute bottom-[-16px] left-0 w-full overflow-hidden leading-none mt-10">
          <svg
            className="relative block w-[calc(100%+1.3px)] h-[16px] md:h-[17px] z-10"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
          >
            <path
              d="M0,0V7.23C0,65.52,268.63,112.77,600,112.77S1200,65.52,1200,7.23V0Z"
              className={
                dashboardData?.totalWinnings >= 0
                  ? "fill-[#009E5F]"
                  : "fill-[#EE203D]"
              }
            />
            <path
              d="M0,0V7.23C0,65.52,268.63,112.77,600,112.77S1200,65.52,1200,7.23V0Z"
              className={
                dashboardData?.totalWinnings >= 0
                  ? "fill-[#F5FDF7]"
                  : "fill-[#FFEEEE]"
              }
              transform="translate(0,-20)"
            />
          </svg>
        </div>
      </div>

      <div className="bg-white p-4 relative">
        <div className="w-full flex justify-center gap-4 absolute top-[-16px] left-1/2 z-20 transform -translate-x-1/2">
          <Button
            variant="primary"
            onClick={() => navigate("/create-new-game")}
          >
            <Plus size={16} />
            NEW GAME
          </Button>
          <Button
            variant="outlineWithIcon"
            onClick={() => navigate("/create-new-room")}
          >
            <Plus size={16} />
            NEW ROOM
          </Button>
        </div>

        {/* Active Games Section */}
        <div className="space-y-4 my-6">
          <h3
            className="text-xl font-semibold mb-3 mt-8"
            style={{ fontFamily: '"Bricolage Grotesque", sans-serif' }}
          >
            Active Games
          </h3>
          {dashboardData.activeGames.map((game, index) => (
            <GameEntry key={`active-${game.gameId}-${index}`} {...game} />
          ))}
          {dashboardData.activeGames.length === 0 && (
            <p className="text-gray-500 text-center py-4">No active games</p>
          )}
        </div>

        {/* Recent Games Section */}
        <div className="space-y-4 mt-8">
          <h3
            className="text-xl font-semibold mb-3"
            style={{ fontFamily: '"Bricolage Grotesque", sans-serif' }}
          >
            Recent Games
          </h3>
          {dashboardData.lastGames.map((game, index) => (
            <GameEntry key={`recent-${game.gameId}-${index}`} {...game} />
          ))}
          {dashboardData.lastGames.length === 0 && (
            <p className="text-gray-500 text-center py-4">No recent games</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Home;
