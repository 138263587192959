import React from "react";

const LoadingMessages = {
  home: {
    primary: "Loading your poker dashboard...",
    secondary: [
      "Tracking active tables",
      "Calculating your wins and losses",
      "Getting latest game updates",
      "Finding active tables",
    ][Math.floor(Math.random() * 4)],
  },
  gameList: {
    primary: "Gathering the players...",
    secondary: [
      "Checking the blinds",
      "Bringing the action",
      "Finding your perfect table",
      "Scouting the competition",
    ][Math.floor(Math.random() * 4)],
  },
  roomsList: {
    primary: "Loading your poker rooms...",
    secondary: [
      "Monitoring your hosted tables",
      "Checking your active tables",
      "Reviewing your room status",
      "Getting your room updates",
    ][Math.floor(Math.random() * 4)],
  },
  gamesAsPlayer: {
    primary: "Loading your games...",
    secondary: [
      "Calculating your winnings",
      "Checking your hands played",
      "Reviewing your game history",
      "Getting your player stats",
    ][Math.floor(Math.random() * 4)],
  },
  gamesAsHost: {
    primary: "Loading hosted games...",
    secondary: [
      "Checking your hosted sessions",
      "Reviewing table activity",
      "Getting host records",
      "Monitoring game history",
    ][Math.floor(Math.random() * 4)],
  },
  gameDetails: {
    primary: "Loading game details...",
    secondary: [
      "Tracking table activity",
      "Calculating player balances",
      "Checking game status",
      "Reviewing player positions",
    ][Math.floor(Math.random() * 4)],
  },
  roomDetails: {
    primary: "Loading room details...",
    secondary: [
      "Checking room settings",
      "Getting room configuration",
      "Loading room preferences",
      "Retrieving room status",
    ][Math.floor(Math.random() * 4)],
  },
  gameCreate: {
    primary: "Setting up your game...",
    secondary: [
      "Picking the perfect deck",
      "Warming up the dealer",
      "Arranging the seats",
      "Getting ready for the action",
    ][Math.floor(Math.random() * 4)],
  },
  roomCreate: {
    primary: "Creating your poker room...",
    secondary: [
      "Rolling out the green felt",
      "Setting the perfect mood",
      "Preparing the atmosphere",
      "Organizing the chips",
    ][Math.floor(Math.random() * 4)],
  },
  gameJoin: {
    primary: "Taking your seat...",
    secondary: [
      "Counting your chips",
      "Eyeing the competition",
      "Getting your game face on",
      "Preparing for the showdown",
    ][Math.floor(Math.random() * 4)],
  },
  roomJoin: {
    primary: "Entering the room...",
    secondary: [
      "Finding your lucky seat",
      "Meeting the players",
      "Checking the action",
      "Sizing up the table",
    ][Math.floor(Math.random() * 4)],
  },
  gameStats: {
    primary: "Running the numbers...",
    secondary: [
      "Calculating your wins",
      "Tracking your bluffs",
      "Analyzing your plays",
      "Counting your chips",
    ][Math.floor(Math.random() * 4)],
  },
  payout: {
    primary: "Collecting your winnings...",
    secondary: [
      "Stacking the chips",
      "Counting the pot",
      "Preparing your payout",
      "Adding to your bankroll",
    ][Math.floor(Math.random() * 4)],
  },
  roundStart: {
    primary: "Starting new round...",
    secondary: [
      "Shuffling up and dealing",
      "Getting the cards ready",
      "Posting the blinds",
      "Bringing new cards",
    ][Math.floor(Math.random() * 4)],
  },
  playerSearch: {
    primary: "Finding players...",
    secondary: [
      "Checking the network",
      "Searching the tables",
      "Looking for action",
      "Scouting opponents",
    ][Math.floor(Math.random() * 4)],
  },
  profile: {
    primary: "Loading your profile...",
    secondary: [
      "Getting your account details",
      "Loading your preferences",
      "Fetching user information",
      "Setting up your profile",
    ][Math.floor(Math.random() * 4)],
  },
  default: {
    primary: "Dealing the cards...",
    secondary: [
      "Getting everything ready",
      "Preparing the table",
      "Setting up your game",
      "Shuffling the deck",
    ][Math.floor(Math.random() * 4)],
  },
};

const Loader = ({ context = "default" }) => {
  const loadingText = LoadingMessages[context] || LoadingMessages.default;

  const positions = [
    { x: -1, y: -1 },
    { x: 1, y: -1 },
    { x: 1, y: 1 },
    { x: -1, y: 1 },
  ];

  return (
    <div className="flex flex-col items-center justify-center min-h-[60vh]">
      <div className="relative w-48 h-48">
        {[0, 1, 2, 3].map((index) => (
          <div
            key={index}
            className="absolute w-12 h-16"
            style={{
              left: "50%",
              top: "50%",
              marginLeft: "-1.5rem",
              marginTop: "-2rem",
              animation: "cardRotate 4s infinite linear",
              transform: `translate(${positions[index].x * 2}rem, ${
                positions[index].y * 2
              }rem)`,
            }}
          >
            <div
              className="w-full h-full relative"
              style={{
                animation: "cardFlip 2s infinite",
                animationDelay: `${index * 0.1}s`,
              }}
            >
              {/* Card Front */}
              <div
                className="absolute inset-0 bg-white rounded-lg border-2 border-gray-200 shadow-lg"
                style={{ backfaceVisibility: "hidden" }}
              >
                <span
                  className={`fixed top-1 left-1 text-xs ${
                    index % 2 === 0 ? "text-red-600" : "text-black"
                  }`}
                >
                  A
                </span>
                <span
                  className={`fixed bottom-1 right-1 text-xs ${
                    index % 2 === 0 ? "text-red-600" : "text-black"
                  }`}
                >
                  A
                </span>
                <div className="absolute inset-0 flex items-center justify-center">
                  {index === 0 && (
                    <span className="text-red-600 text-2xl">♥</span>
                  )}
                  {index === 1 && (
                    <span className="text-black text-2xl">♠</span>
                  )}
                  {index === 2 && (
                    <span className="text-red-600 text-2xl">♦</span>
                  )}
                  {index === 3 && (
                    <span className="text-black text-2xl">♣</span>
                  )}
                </div>
              </div>
              {/* Card Back */}
              <div
                className="absolute inset-0 bg-blue-600 rounded-lg border-2 border-gray-200 shadow-lg"
                style={{
                  backfaceVisibility: "hidden",
                  transform: "rotateY(180deg)",
                  background: "linear-gradient(135deg, #2563eb, #1d4ed8)",
                }}
              >
                <div className="absolute inset-1 border-2 border-white border-opacity-20 rounded"></div>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Dynamic Loading Text */}
      <div className="mt-8 text-center">
        <p className="text-gray-600 font-medium animate-pulse">
          {loadingText.primary}
        </p>
        <p className="text-sm text-gray-500 mt-2">{loadingText.secondary}</p>
      </div>

      <style jsx="true">{`
        @keyframes cardRotate {
          0% {
            transform: translate(var(--pos1X), var(--pos1Y));
          }
          25% {
            transform: translate(var(--pos2X), var(--pos2Y));
          }
          50% {
            transform: translate(var(--pos3X), var(--pos3Y));
          }
          75% {
            transform: translate(var(--pos4X), var(--pos4Y));
          }
          100% {
            transform: translate(var(--pos1X), var(--pos1Y));
          }
        }

        @keyframes cardFlip {
          0% {
            transform: rotateY(0deg);
          }
          25% {
            transform: rotateY(180deg);
          }
          75% {
            transform: rotateY(180deg);
          }
          100% {
            transform: rotateY(360deg);
          }
        }

        /* Position coordinates for the grid */
        div:nth-child(1) {
          --pos1X: -2rem;
          --pos1Y: -2rem;
          --pos2X: 2rem;
          --pos2Y: -2rem;
          --pos3X: 2rem;
          --pos3Y: 2rem;
          --pos4X: -2rem;
          --pos4Y: 2rem;
        }

        div:nth-child(2) {
          animation-delay: 0.5s;
          --pos1X: 2rem;
          --pos1Y: -2rem;
          --pos2X: 2rem;
          --pos2Y: 2rem;
          --pos3X: -2rem;
          --pos3Y: 2rem;
          --pos4X: -2rem;
          --pos4Y: -2rem;
        }

        div:nth-child(3) {
          animation-delay: 1s;
          --pos1X: 2rem;
          --pos1Y: 2rem;
          --pos2X: -2rem;
          --pos2Y: 2rem;
          --pos3X: -2rem;
          --pos3Y: -2rem;
          --pos4X: 2rem;
          --pos4Y: -2rem;
        }

        div:nth-child(4) {
          animation-delay: 1.5s;
          --pos1X: -2rem;
          --pos1Y: 2rem;
          --pos2X: -2rem;
          --pos2Y: -2rem;
          --pos3X: 2rem;
          --pos3Y: -2rem;
          --pos4X: 2rem;
          --pos4Y: 2rem;
        }
      `}</style>
    </div>
  );
};

export default Loader;
