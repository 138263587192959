import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ChevronLeft, Pencil, X, ImageIcon, Images, User2 } from "lucide-react";
import { useSnackbar } from "../../context/SnackbarContext";
import Button from "../../components/Button/Button";
import Input from "../../components/Input/Input";
import { API } from "../../services";

// Sample game data
const SAMPLE_GAME_DATA = {
  gamesPlayed: 150,
  gamesWon: 89,
  winRate: "59.3%",
  highestScore: 2890,
  totalPoints: 28500,
};

// Avatar options with dynamic generation
const AVATAR_OPTIONS = [
  "avatar1.svg",
  "avatar2.svg",
  "avatar3.svg",
  "avatar4.svg",
].map((avatarFilename, index) => ({
  id: index + 1,
  src: `/avatars/${avatarFilename}`,
  filename: avatarFilename,
  alt: `Avatar ${index + 1}`,
}));

const AvatarSelectionDialog = ({
  isOpen,
  onClose,
  onSelect,
  selectedAvatarId,
}) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg shadow-lg w-full max-w-md">
        <div className="p-4">
          <div className="flex justify-between items-center mb-4">
            <div className="p-3 rounded-lg border border-[#E4E7EC] bg-white shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)]">
              <ImageIcon className="text-gray-600" size={24} />
            </div>
            <button
              onClick={onClose}
              className="text-gray-400 hover:text-gray-600 transition-colors p-2"
              aria-label="Close modal"
            >
              <X size={24} />
            </button>
          </div>

          <h2 className="text-xl font-bold mb-2">Choose your avatar</h2>
          <p className="text-gray-600 mb-6">
            Select an avatar for your profile
          </p>

          <div className="grid grid-cols-4 gap-4 mb-6">
            {AVATAR_OPTIONS.map((avatar) => (
              <button
                key={avatar.id}
                onClick={() => onSelect(avatar)}
                className={`relative rounded-full w-max overflow-hidden transition-all ${
                  selectedAvatarId === avatar.id
                    ? "ring-2 ring-blue-500 scale-105"
                    : "hover:scale-105"
                }`}
              >
                <img
                  src={avatar.src}
                  alt={avatar.alt}
                  className="w-16 h-16 rounded-full object-cover"
                />
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const EditProfile = () => {
  const { showSnackbar } = useSnackbar();
  const [formData, setFormData] = useState({
    email: "",
    name: "",
  });
  const [selectedAvatar, setSelectedAvatar] = useState({
    src: AVATAR_OPTIONS[0].src,
    id: 1,
    filename: AVATAR_OPTIONS[0].filename,
  });
  const [isAvatarDialogOpen, setIsAvatarDialogOpen] = useState(false);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const token = localStorage.getItem("token");
        const {
          data: { data },
        } = await API.getUserProfile(token);
        setFormData(data);
        setSelectedAvatar({
          src: `/avatars/${data.avatar_filename}`,
          id: Number(data.avatar_filename?.split(".")[0]?.slice(-1) || 0),
          filename: data.avatar_filename,
        });
      } catch (error) {
        console.error("Error fetching user profile:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserProfile();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    setErrors((prev) => ({ ...prev, [name]: "" }));
  };

  const handleAvatarSelect = (avatar) => {
    setSelectedAvatar({
      src: avatar.src,
      id: avatar.id,
      filename: avatar.filename,
    });
    setFormData((prev) => ({ ...prev, avatar_filename: avatar.filename }));
    setIsAvatarDialogOpen(false);
  };

  const validateForm = () => {
    let formErrors = {};
    if (!formData.email) {
      formErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      formErrors.email = "Email is invalid";
    }
    if (!formData.name) {
      formErrors.name = "Name is required";
    }
    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setIsLoading(true);
      try {
        const payload = {
          ...formData,
        };
        const {
          data: { success, message },
        } = await API.updateUserProfile(payload, localStorage.getItem("token"));
        success && showSnackbar("Profile updated successfully!", "success");
        navigate("/profile");
      } catch (error) {
        const errorMessage =
          error.response?.data?.message || "Failed to update profile";
        showSnackbar(errorMessage, "error");
        if (error.response?.data?.errors) {
          const fieldErrors = {};
          error.response.data.errors.forEach((err) => {
            fieldErrors[err.field] = err.message;
          });
          setErrors(fieldErrors);
        } else {
          setErrors({
            general: errorMessage,
          });
        }
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleBack = () => {
    if (!isLoading) {
      navigate(-1);
    }
  };

  return (
    <div className="max-w-3xl p-4 pt-0 mx-auto flex flex-col gap-2 h-[calc(100%-5rem)] overflow-auto">
      <header className="flex items-center mb-4 pt-4 sticky top-0 z-20 bg-white">
        <button onClick={handleBack} className="py-2">
          <ChevronLeft size={24} />
        </button>
        <h1 className="text-md font-bold w-full text-center">Edit Profile</h1>
      </header>

      <form className="space-y-6" onSubmit={handleSubmit}>
        {/* Avatar Section */}
        <div className="flex flex-col items-center space-y-2">
          <div className="relative inline-block">
            <img
              src={selectedAvatar.src}
              alt="Profile"
              className="w-24 h-24 rounded-full object-cover border-2 border-gray-200"
            />
            <button
              type="button"
              onClick={() => setIsAvatarDialogOpen(true)}
              className="absolute bottom-0 right-0 bg-white rounded-full p-1.5 shadow-md border border-gray-200 hover:bg-gray-50 transition-colors"
            >
              <Pencil size={14} className="text-gray-600" />
            </button>
          </div>
        </div>

        <Input
          label="Username"
          type="text"
          value={formData?.username || ""}
          name="username"
          disabled={true}
          className="bg-gray-100"
        />

        <Input
          label="Email"
          type="email"
          placeholder="e.g. xyz@gmail.com"
          value={formData.email}
          onChange={handleChange}
          name="email"
          errorMessage={errors.email}
          // disabled={isLoading}
          disabled={true}
        />

        <Input
          label="Name"
          type="text"
          placeholder="Enter your name"
          value={formData.name}
          onChange={handleChange}
          name="name"
          errorMessage={errors.name}
          disabled={isLoading}
        />

        <Button
          variant="primary"
          fullWidth
          type="submit"
          disabled={isLoading}
          loading={isLoading}
        >
          {isLoading ? "Updating..." : "Update Changes"}
        </Button>
      </form>

      {/* Custom Avatar Selection Dialog */}
      <AvatarSelectionDialog
        isOpen={isAvatarDialogOpen}
        onClose={() => setIsAvatarDialogOpen(false)}
        onSelect={handleAvatarSelect}
        selectedAvatarId={selectedAvatar.id}
      />
    </div>
  );
};

export default EditProfile;
