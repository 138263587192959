import React, { useState, useEffect } from "react";
import { ChevronLeft, Users, Gamepad2, Settings2 } from "lucide-react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useSnackbar } from "../../context/SnackbarContext";
import Button from "../../components/Button/Button";
import RoomEntry from "../../components/RoomEntry/RoomEntry";
import { API } from "../../services";
import Loader from "../../components/Loader/Loader";

const RoomsList = () => {
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const [rooms, setRooms] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchRooms();
  }, []);

  const fetchRooms = async () => {
    try {
      const { data } = await API.getUserRoomsList(
        localStorage.getItem("token")
      );

      const transformedRooms = data.map((room) => ({
        id: room.room_id,
        name: room.room_name,
        location: room.room_location,
        activeGames: room.activeGames || 0,
        totalGames: room.games || 0,
        totalPlayers: room.players || 0,
      }));

      setRooms(transformedRooms);
    } catch (error) {
      const message = error?.response?.data?.error;
      showSnackbar(message || "Failed to fetch rooms", "error");
    } finally {
      setIsLoading(false);
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  const handleCreateRoom = () => {
    navigate("/create-new-room");
  };

  if (isLoading) {
    return <Loader context="roomsList" />;
  }

  return (
    <div className="max-w-3xl p-4 pt-0 mx-auto flex flex-col gap-2 h-[calc(100%-5rem)] overflow-auto">
      <header className="flex items-center mb-4 pt-4 sticky top-0 z-20 bg-white">
        <button onClick={handleBack} className="py-2">
          <ChevronLeft size={24} />
        </button>
        <h1 className="text-md font-bold w-full text-center">Rooms</h1>
      </header>

      {/* Rooms List */}
      <div className="space-y-2">
        {rooms.length === 0 ? (
          <div className="flex flex-col items-center justify-center my-6 gap-6">
            <div className="text-center text-gray-500">
              No rooms found. Create your first room!
            </div>
            <Button onClick={handleCreateRoom} variant="primary">
              Create new room
            </Button>
          </div>
        ) : (
          rooms.map((room) => (
            <RoomEntry
              key={room.id}
              roomId={room.id}
              players={room.totalPlayers}
              name={room.name}
              location={room.location}
              activeGames={room.activeGames}
              games={room.totalGames}
            />
          ))
        )}
      </div>
    </div>
  );
};

export default RoomsList;
