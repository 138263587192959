import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import {
  Login,
  Profile,
  CreateNewRoom,
  CreateNewGame,
  GameDetails,
  Home,
  GoogleRegister,
} from "./pages";
import BottomNavigation from "./components/BottomNavigation/BottomNavigation";
import "./App.css";
import EditProfile from "./pages/Profile/EditProfile";
import Games from "./pages/Games/Games";
import Rooms from "./pages/Rooms/Rooms";
import EditRoom from "./pages/Rooms/EditRoom";
import GameSettlementDetails from "./pages/GameDetails/SettlementDetails";
import FloatingActionButton from "./components/FloatingActionButton/FloatingActionButton";
import { AuthProvider, useAuth } from "./services/hooks/useAuth";
import GameDefaults from "./pages/Profile/GameDefaults";

const ProtectedRoute = ({ children }) => {
  const { isAuthenticated, isLoading } = useAuth();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

const PublicOnlyRoute = ({ children }) => {
  const { isAuthenticated, isLoading } = useAuth();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isAuthenticated) {
    return <Navigate to="/home" replace />;
  }

  return children;
};

const AppContent = () => {
  const { isAuthenticated } = useAuth();

  return (
    <>
      <Routes>
        {/* Public routes */}
        <Route
          path="/login"
          element={
            <PublicOnlyRoute>
              <Login />
            </PublicOnlyRoute>
          }
        />
        <Route path="/register" element={<GoogleRegister />} />
        <Route path="/complete-registration" element={<GoogleRegister />} />

        {/* Protected routes */}
        <Route
          path="/profile"
          element={
            <ProtectedRoute>
              <Profile />
            </ProtectedRoute>
          }
        />
        <Route
          path="/profile/edit-profile"
          element={
            <ProtectedRoute>
              <EditProfile />
            </ProtectedRoute>
          }
        />
        <Route
          path="/profile/game-defaults"
          element={
            <ProtectedRoute>
              <GameDefaults />
            </ProtectedRoute>
          }
        />
        <Route
          path="/home"
          element={
            <ProtectedRoute>
              <Home />
            </ProtectedRoute>
          }
        />
        <Route
          path="/create-new-room"
          element={
            <ProtectedRoute>
              <CreateNewRoom />
            </ProtectedRoute>
          }
        />
        <Route
          path="/create-new-game"
          element={
            <ProtectedRoute>
              <CreateNewGame />
            </ProtectedRoute>
          }
        />
        <Route
          path="/edit-game/:gameId"
          element={
            <ProtectedRoute>
              <CreateNewGame />
            </ProtectedRoute>
          }
        />
        <Route
          path="/game-details/:gameId"
          element={
            <ProtectedRoute>
              <GameDetails />
            </ProtectedRoute>
          }
        />
        <Route
          path="/settlement-details/:gameId"
          element={
            <ProtectedRoute>
              <GameSettlementDetails />
            </ProtectedRoute>
          }
        />
        <Route
          path="/games"
          element={
            <ProtectedRoute>
              <Games />
            </ProtectedRoute>
          }
        />
        <Route
          path="/rooms"
          element={
            <ProtectedRoute>
              <Rooms />
            </ProtectedRoute>
          }
        />
        <Route
          path="/rooms/:roomId/edit"
          element={
            <ProtectedRoute>
              <EditRoom />
            </ProtectedRoute>
          }
        />
        <Route path="/" element={<Navigate to="/login" />} />
      </Routes>

      {isAuthenticated && (
        <>
          <FloatingActionButton />
          <BottomNavigation />
        </>
      )}
    </>
  );
};

function App() {
  return (
    <AuthProvider>
      <Router>
        <AppContent />
      </Router>
    </AuthProvider>
  );
}

export default App;
