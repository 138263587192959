import React from "react";
import { X, Trash2 } from "lucide-react";
import axios from "axios";
import { API } from "../../services";

const DeleteModal = ({ isOpen, onClose, gameId }) => {
  if (!isOpen) return null;
  const handleDelete = async () => {
    try {
      const {
        data: { message, data },
      } = await API.deleteGame(gameId, localStorage.getItem("token"));
      onClose();
    } catch (error) {
      console.error("Error deleting game:", error.message);
      // You could add error state handling here if needed
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg shadow-lg w-full max-w-md">
        <div className="p-4">
          <div className="flex justify-between items-center mb-4">
            <div className="bg-red-100 p-2 rounded-lg">
              <Trash2 className="text-red-500" size={24} />
            </div>
            <button
              onClick={onClose}
              className="text-gray-400 hover:text-gray-600 transition-colors p-2"
              aria-label="Close modal"
            >
              <X size={24} />
            </button>
          </div>

          <h2 className="text-xl font-bold mb-2">
            Delete the game permanently?
          </h2>
          <p className="text-gray-600 mb-6">Please confirm to delete</p>

          <button
            onClick={handleDelete}
            className="w-full bg-red-500 text-white py-3 rounded-lg hover:bg-red-600"
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  );
};

export default React.memo(DeleteModal);
