import React, { useState, useEffect, useCallback } from "react";
import { ChevronLeft } from "lucide-react";
import { ReactComponent as GameIcon } from "../../assets/icons/create-game.svg";
import { ReactComponent as RoomIcon } from "../../assets/icons/create-room.svg";
import Button from "../../components/Button/Button";
import Input from "../../components/Input/Input";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "../../context/SnackbarContext";
import { API } from "../../services";
import { CURRENCIES } from "../../utilities/commonValues";
import Loader from "../../components/Loader/Loader";

const handleNumberInput = (value) => {
  const cleanValue = value.toString().replace(/[^0-9.]/g, "");
  if (cleanValue === "") return "";
  const parts = cleanValue.split(".");
  if (parts.length > 2) return parts[0] + "." + parts[1];
  const numberValue = Number(cleanValue);
  return isNaN(numberValue) ? "" : cleanValue;
};

const formatDisplayValue = (value) => {
  if (value === "" || value === null || value === undefined) return "";
  const number = Number(value);
  return isNaN(number) ? "" : number.toLocaleString();
};

const GameDefaults = () => {
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [loading, setLoading] = useState(true);
  const [roomOptions, setRoomOptions] = useState([]);
  const [formData, setFormData] = useState({
    room: "",
    min_buyin: "",
    gameType: "Cash Game",
    currency: "INR",
    type: "NLHE",
    limit: "no limit",
    smallBlind: "",
    bigBlind: "",
    playersPrivacy: false,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const [defaultsResponse, roomsResponse] = await Promise.all([
          API.getGameDefaults(localStorage.getItem("token")),
          API.getUserRoomsList(localStorage.getItem("token")),
        ]);

        setRoomOptions(
          roomsResponse?.data?.map((room) => ({
            label: room?.room_name,
            value: room?.room_id,
          }))
        );

        if (defaultsResponse?.data?.data) {
          const defaults = defaultsResponse.data.data;
          console.log(
            "defaults: ",
            defaults.players_privacy,
            typeof defaults.players_privacy
          );
          setFormData((prev) => ({
            ...prev,
            room: defaults.room_id || "",
            min_buyin: defaults.buy_in?.toString() || "",
            gameType: defaults.game_type || "Cash Game",
            currency: defaults.currency || "INR",
            type: defaults.poker_type || "NLHE",
            limit: defaults.limit_type || "no limit",
            smallBlind: defaults.small_blind?.toString() || "",
            bigBlind: defaults.big_blind?.toString() || "",
            playersPrivacy: defaults.players_privacy,
          }));
        }
      } catch (error) {
        showSnackbar("Failed to fetch data", "error");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    const numberFields = ["min_buyin", "smallBlind", "bigBlind"];
    const isNumberField = numberFields.includes(name);

    setFormData((prevData) => {
      if (type === "checkbox") {
        return { ...prevData, [name]: checked };
      } else {
        const newValue = isNumberField ? handleNumberInput(value) : value;
        return { ...prevData, [name]: newValue };
      }
    });
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const handleSelectChange = (name, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const validateForm = useCallback(() => {
    const newErrors = {};
    if (!formData.room) newErrors.room = "Room selection is required";

    const numberFields = [
      { name: "min_buyin", label: "Minimum buy-in" },
      { name: "smallBlind", label: "Small Blind" },
      { name: "bigBlind", label: "Big Blind" },
    ];

    numberFields.forEach(({ name, label }) => {
      const value = Number(formData[name]);
      if (!formData[name] || isNaN(value) || value <= 0) {
        newErrors[name] = `${label} must be a valid number greater than 0`;
      }
    });

    if (!formData.gameType) newErrors.gameType = "Game type is required";
    if (!formData.currency)
      newErrors.currency = "Currency selection is required";
    if (!formData.type) newErrors.type = "Type is required";
    if (!formData.limit) newErrors.limit = "Limit is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  }, [formData]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setIsSubmitting(true);
    try {
      const selectedRoom = roomOptions.find(
        (room) => room.value === formData.room
      );
      const payload = {
        room_id: formData.room,
        room_name: selectedRoom?.label || "",
        buy_in: Number(formData.min_buyin),
        small_blind: Number(formData.smallBlind),
        big_blind: Number(formData.bigBlind),
        game_type: formData.gameType,
        currency: formData.currency,
        currency_symbol: CURRENCIES[formData.currency],
        poker_type: formData.type,
        limit_type: formData.limit,
        players_privacy: formData.playersPrivacy,
      };

      await API.updateGameDefaults(payload, localStorage.getItem("token"));
      showSnackbar("Game defaults updated successfully!", "success");
      navigate(-1);
    } catch (error) {
      const message = error?.response?.data?.message;
      showSnackbar(message || "Failed to update game defaults", "error");
    } finally {
      setIsSubmitting(false);
    }
  };

  const typeOptions = [
    { label: "No-Limit Hold'em (NLHE)", value: "NLHE" },
    { label: "Pot-Limit Omaha (PLO)", value: "PLO" },
  ];

  const gameOptions = [{ label: "Cash Game", value: "Cash Game" }];

  const currencyOptions = [
    {
      label: (
        <div className="flex gap-1 items-center align-middle">&#8377; INR</div>
      ),
      value: "INR",
    },
  ];

  if (loading) return <Loader context="gameDefaults" />;

  return (
    <form
      onSubmit={handleSubmit}
      className="flex flex-col min-h-screen bg-white p-4"
    >
      <div className="w-full max-w-md mx-auto space-y-6 mb-16">
        <button type="button" className="mb-4" onClick={() => navigate(-1)}>
          <ChevronLeft size={24} />
        </button>

        <div className="flex flex-col items-center mb-6">
          <div className="rounded-[10px] border border-gray-200 bg-white shadow-sm p-3">
            <GameIcon />
          </div>
          <h1 className="text-2xl font-bold">Game Defaults</h1>
        </div>

        <div className="space-y-6">
          <div className="space-y-4">
            <h2 className="text-lg font-semibold">Game Details</h2>

            <Input
              prefixIcon={<RoomIcon className="h-5 w-5" />}
              type="select"
              name="room"
              options={[
                ...roomOptions,
                {
                  value: "create-new-room",
                  label: (
                    <Button
                      variant="link"
                      fullWidth
                      onClick={() => navigate("/create-new-room")}
                    >
                      + Create new room
                    </Button>
                  ),
                },
              ]}
              placeholder="Select Room"
              value={formData.room}
              onChange={(e) => handleSelectChange("room", e.target.value)}
              errorMessage={errors.room}
            />
            <Input
              type="select"
              name="gameType"
              options={gameOptions}
              placeholder="Select game"
              value={formData.gameType}
              onChange={(e) => handleSelectChange("gameType", e.target.value)}
              errorMessage={errors.gameType}
            />
            <Input
              type="select"
              name="type"
              options={typeOptions}
              placeholder="Select type"
              value={formData.type}
              onChange={(e) => handleSelectChange("type", e.target.value)}
              errorMessage={errors.type}
            />
            <div className="grid grid-cols-7 gap-4">
              <div className="col-span-2">
                <Input
                  type="select"
                  name="currency"
                  options={currencyOptions}
                  placeholder="Select currency"
                  value={formData.currency}
                  onChange={(e) =>
                    handleSelectChange("currency", e.target.value)
                  }
                  errorMessage={errors.currency}
                />
              </div>
              <div className="col-span-5">
                <Input
                  type="text"
                  name="min_buyin"
                  placeholder="Minimum Buyin"
                  className="w-full p-3 border rounded-lg"
                  value={formatDisplayValue(formData.min_buyin)}
                  onChange={handleInputChange}
                  errorMessage={errors.min_buyin}
                />
              </div>
            </div>

            <div className="flex space-x-4">
              <Input
                type="text"
                name="smallBlind"
                placeholder="Small Blind"
                className="p-3 border rounded-lg"
                value={formatDisplayValue(formData.smallBlind)}
                onChange={handleInputChange}
                errorMessage={errors.smallBlind}
              />
              <Input
                type="text"
                name="bigBlind"
                placeholder="Big Blind"
                className="p-3 border rounded-lg"
                value={formatDisplayValue(formData.bigBlind)}
                onChange={handleInputChange}
                errorMessage={errors.bigBlind}
              />
            </div>
          </div>

          <Input
            type="checkbox"
            label="Keep players' details private"
            name="playersPrivacy"
            onChange={handleInputChange}
            value={formData.playersPrivacy}
          />

          <Button
            variant="primary"
            className="w-full my-8"
            type="submit"
            disabled={isSubmitting}
          >
            {isSubmitting ? "Updating..." : "Update defaults"}
          </Button>
        </div>
      </div>
    </form>
  );
};

export default GameDefaults;
