import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { ReactComponent as Logo } from "../../assets/logo.svg";
import Input from "../../components/Input/Input";
import Button from "../../components/Button/Button";
import { ArrowLeft } from "lucide-react";
import { useSnackbar } from "../../context/SnackbarContext";
import { API } from "../../services";
import { useAuth } from "../../services/hooks/useAuth";

const getRandomAvatar = () => {
  const avatars = ["avatar1.png", "avatar2.png", "avatar3.png", "avatar4.png"];
  return avatars[Math.floor(Math.random() * avatars.length)];
};

const SignUp = () => {
  const { showSnackbar } = useSnackbar();
  const { login } = useAuth();
  const [formData, setFormData] = useState({
    email: "",
    name: "",
    username: "",
    password: "",
    avatar_filename: getRandomAvatar(),
  });
  const [errors, setErrors] = useState({});
  const [googleId, setGoogleId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const { name, googleId, email } = location.state || {};
    if (email && googleId && name) {
      setFormData((prev) => ({
        ...prev,
        email,
        name,
      }));
      setGoogleId(googleId);
    }
  }, [location]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: name === "username" ? value.toLowerCase() : value,
    }));
    setErrors((prev) => ({ ...prev, [name]: "" }));
  };

  const validateForm = () => {
    let formErrors = {};
    if (!formData.email) {
      formErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      formErrors.email = "Email is invalid";
    }
    if (!formData.name) {
      formErrors.name = "Name is required";
    }
    if (!formData.username) {
      formErrors.username = "Username is required";
    } else if (formData.username.length < 3) {
      formErrors.username = "Username must be at least 3 characters";
    }
    if (!googleId && !formData.password) {
      formErrors.password = "Password is required";
    } else if (!googleId && formData.password.length < 6) {
      formErrors.password = "Password must be at least 6 characters";
    }
    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setIsLoading(true);
      try {
        const dataToSend = googleId
          ? { ...formData, google_id: googleId }
          : formData;
        const response = await (googleId
          ? API.registerUserWithGoogle(dataToSend)
          : API.registerUser(dataToSend));

        await login(response.data.token);
        showSnackbar("Registration successful!", "success");
        navigate("/home");
      } catch (error) {
        const errorMessage =
          error.response?.data?.error || "Registration failed";
        showSnackbar(errorMessage, "error");
        if (error.response?.data?.errors) {
          const fieldErrors = {};
          error.response.data.errors.forEach((err) => {
            fieldErrors[err.field] = err.message;
          });
          setErrors(fieldErrors);
        } else {
          setErrors({
            general: errorMessage,
          });
        }
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleBack = () => {
    if (!isLoading) {
      navigate(-1);
    }
  };

  return (
    <div className="flex flex-col items-center min-h-screen bg-white p-4">
      <div className="w-full max-w-md space-y-8">
        <div className="space-y-6">
          <div className="flex flex-col align-center gap-8">
            <button
              onClick={handleBack}
              className="p-2 mr-4"
              disabled={isLoading}
            >
              <ArrowLeft size={24} />
            </button>
            <div>
              <h2
                className="text-[2rem] font-semibold mb-6 leading-[130%] tracking-[-0.64px] text-[#1A1C1E]"
                style={{ fontFamily: '"Bricolage Grotesque", sans-serif' }}
              >
                Sign up
              </h2>
              <p className="text-sm text-gray-600 text-left">
                Create an account to continue!
              </p>
            </div>
          </div>

          <form className="space-y-4" onSubmit={handleSubmit}>
            <Input
              label="Email"
              type="email"
              placeholder="e.g. xyz@gmail.com"
              value={formData.email}
              onChange={handleChange}
              name="email"
              errorMessage={errors.email}
              disabled={!!googleId || isLoading}
            />

            <Input
              label="Name"
              type="text"
              placeholder="Enter your name"
              value={formData.name}
              onChange={handleChange}
              name="name"
              errorMessage={errors.name}
              disabled={!!googleId || isLoading}
            />

            <Input
              label="Username"
              type="text"
              placeholder="Choose a username"
              value={formData.username?.toLowerCase()}
              onChange={handleChange}
              name="username"
              errorMessage={errors.username}
              disabled={isLoading}
            />

            {!googleId && (
              <Input
                label="Password"
                type="password"
                placeholder="Enter your password"
                value={formData.password}
                onChange={handleChange}
                name="password"
                errorMessage={errors.password}
                disabled={isLoading}
              />
            )}

            <Button
              variant="primary"
              className="w-full"
              type="submit"
              disabled={isLoading}
              loading={isLoading}
            >
              {isLoading
                ? googleId
                  ? "Completing Registration..."
                  : "Signing up..."
                : googleId
                ? "Complete Registration"
                : "Sign up"}
            </Button>
          </form>

          {!googleId && (
            <div className="w-full flex justify-center">
              <p
                className="flex items-center text-[0.75rem] font-medium text-[#6C7278] leading-[140%] tracking-[-0.12px]"
                style={{ fontFamily: "Inter, sans-serif" }}
              >
                Already have an account?
                <Button
                  variant="link"
                  className="ml-1 p-0 text-blue-600 hover:text-blue-800 text-[0.75rem] font-medium leading-[140%] tracking-[-0.12px]"
                  style={{ fontFamily: "Inter, sans-serif" }}
                  onClick={() => !isLoading && navigate("/login")}
                  disabled={isLoading}
                >
                  Login
                </Button>
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SignUp;
