import React, { useState } from "react";
import { X, KeyRound, Eye, EyeOff } from "lucide-react";
import axios from "axios";
import { useSnackbar } from "../../context/SnackbarContext";
import Button from "../../components/Button/Button";
import Input from "../../components/Input/Input";
import { API } from "../../services";

const PasswordChangeModal = ({ isOpen, onClose }) => {
  const { showSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [showPasswords, setShowPasswords] = useState({
    current: false,
    new: false,
    confirm: false,
  });
  const [formData, setFormData] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
    setErrors((prev) => ({
      ...prev,
      [name]: "",
    }));
  };

  const togglePasswordVisibility = (field) => {
    setShowPasswords((prev) => ({
      ...prev,
      [field]: !prev[field],
    }));
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.currentPassword) {
      newErrors.currentPassword = "Current password is required";
    }
    if (!formData.newPassword) {
      newErrors.newPassword = "New password is required";
    } else if (formData.newPassword.length < 6) {
      newErrors.newPassword = "Password must be at least 6 characters";
    }
    if (!formData.confirmPassword) {
      newErrors.confirmPassword = "Please confirm your new password";
    } else if (formData.newPassword !== formData.confirmPassword) {
      newErrors.confirmPassword = "Passwords do not match";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setIsLoading(true);
    try {
      await API.updateUserProfile(formData, localStorage.getItem("token"));
      showSnackbar("Password updated successfully", "success");
      onClose();
      setFormData({
        currentPassword: "",
        newPassword: "",
        confirmPassword: "",
      });
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || "Failed to update password";
      showSnackbar(errorMessage, "error");
      if (error.response?.data?.field) {
        setErrors({
          [error.response.data.field]: errorMessage,
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg shadow-lg w-full max-w-md">
        <div className="p-4">
          <div className="flex justify-between items-center mb-4">
            <div className="p-3 rounded-lg border border-[#E4E7EC] bg-white shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)]">
              <KeyRound size={24} />
            </div>
            <button
              onClick={onClose}
              className="text-gray-400 hover:text-gray-600 transition-colors p-2"
              aria-label="Close modal"
            >
              <X size={24} />
            </button>
          </div>

          <h2 className="text-xl font-bold mb-2">Change password</h2>
          <p className="text-gray-600 mb-4">
            Enter your current and new password
          </p>

          <form className="space-y-4" onSubmit={handleSubmit}>
            <Input
              label="Current Password"
              type="password"
              placeholder="Enter your password"
              name="currentPassword"
              value={formData.currentPassword}
              onChange={handleChange}
              errorMessage={errors.currentPassword}
              disabled={isLoading}
            />

            <Input
              label="New Password"
              type="password"
              placeholder="Enter new password"
              value={formData.newPassword}
              onChange={handleChange}
              name="newPassword"
              errorMessage={errors.newPassword}
              disabled={isLoading}
            />
            <Input
              label="Confirm New Password"
              type="password"
              placeholder="Confirm new password"
              value={formData.confirmPassword}
              onChange={handleChange}
              name="confirmPassword"
              errorMessage={errors.confirmPassword}
              disabled={isLoading}
            />

            <div className="grid grid-cols-2 gap-4">
              <Button
                type="button"
                variant="outline"
                onClick={onClose}
                disabled={isLoading}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="primary"
                className="flex-1"
                loading={isLoading}
                disabled={isLoading}
              >
                Update Password
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default PasswordChangeModal;
