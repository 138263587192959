import React, { useState, useEffect } from "react";
import {
  X,
  Share2,
  Twitter,
  MessageCircle,
  Copy,
  CheckCheck,
  Instagram,
} from "lucide-react";
import Button from "../../components/Button/Button";

const ShareModal = ({ isOpen, onClose }) => {
  const [copied, setCopied] = useState(false);
  const [shareMessage, setShareMessage] = useState("");
  
  const appUrl = window.location.origin;

  useEffect(() => {
    if (isOpen) {
      const messages = [
        "🎲 Track your poker games like a pro with our app! Never lose track of your wins and losses again. Join now! 📱",
        "💰 Tired of messy poker calculations? Our app makes tracking your sessions a breeze! Download now and stay organized. 🃏",
        "📊 Managing poker profits made easy! Perfect for home games and tournaments. Give it a try! 🎯",
        "🎰 Love poker? Our app is a game-changer for tracking wins and losses. Simple, accurate, and perfect for every player! 💫",
        "🏆 Take your poker game to the next level - the app every serious player needs. Join the smart way to track your journey! 📈",
      ];
      setShareMessage(messages[Math.floor(Math.random() * messages.length)]);
    }
  }, [isOpen]);

  if (!isOpen) return null;

  const shareOptions = [
    {
      name: "WhatsApp",
      Icon: MessageCircle,
      color: "#25D366",
      url: `https://wa.me/?text=${encodeURIComponent(shareMessage + "\n\n" + appUrl)}`,
    },
    {
      name: "Twitter",
      Icon: Twitter,
      color: "#1DA1F2",
      url: `https://twitter.com/intent/tweet?text=${encodeURIComponent(shareMessage)}&url=${encodeURIComponent(appUrl)}`,
    },
    {
      name: "Instagram",
      Icon: Instagram,
      color: "#E4405F",
      url: `instagram://share?text=${encodeURIComponent(shareMessage + "\n\n" + appUrl)}`,
    },
  ];

  const handleShare = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const handleCopyMessage = () => {
    navigator.clipboard.writeText(`${shareMessage}\n\n${appUrl}`);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg shadow-lg w-full max-w-md">
        <div className="p-4">
          <div className="flex justify-between items-center mb-4">
            <div className="p-3 rounded-lg border border-[#E4E7EC] bg-white shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)]">
              <Share2 className="text-gray-600" size={24} />
            </div>
            <button
              onClick={onClose}
              className="text-gray-400 hover:text-gray-600 transition-colors p-2"
              aria-label="Close modal"
            >
              <X size={24} />
            </button>
          </div>

          <h2 className="text-2xl font-bold mb-2">Share app</h2>
          <p className="text-gray-600 mb-6">
            Help others discover this amazing app!
          </p>

          <div className="p-4 bg-gradient-to-br from-blue-50 to-indigo-50 rounded-lg border border-blue-100 mb-6">
            <div className="relative p-4 bg-white rounded-md shadow-sm">
              <p className="text-gray-700 text-sm mb-2 pr-8">{shareMessage}</p>
              <p className="text-sm text-blue-600 font-medium">{appUrl}</p>
              <Button
                variant="ghost"
                className="absolute top-2 right-2 hover:bg-gray-50 p-2 rounded-full"
                onClick={handleCopyMessage}
              >
                {copied ? (
                  <CheckCheck className="h-4 w-4 text-green-600" />
                ) : (
                  <Copy className="h-4 w-4 text-gray-600" />
                )}
              </Button>
            </div>
          </div>

          <div className="space-y-4">
            <div className="text-sm font-medium text-gray-500 mb-3">
              Share via
            </div>
            <div className="grid gap-3">
              {shareOptions.map((option) => (
                <Button
                  key={option.name}
                  variant="outline"
                  onClick={() => handleShare(option.url)}
                  className="flex items-center justify-center gap-2 py-3"
                >
                  <option.Icon size={20} />
                  Share on {option.name}
                </Button>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShareModal;