import React from "react";
import { X, Power } from "lucide-react";
import axios from "axios";
import Button from "../../components/Button/Button";
import { useNavigate } from "react-router-dom";
import { API } from "../../services";
import { formatAmount } from "../../utilities/commonFunction";

const EndGameModal = ({
  isOpen,
  onClose,
  gameId,
  currencySymbol = "$",
  mismatchAmount = 0,
}) => {
  const navigate = useNavigate();
  if (!isOpen) return null;

  const handleEndGame = async () => {
    try {
      const {
        data: { message, data },
      } = await API.endGame(
        gameId,
        {
          mismatchAmount,
        },
        localStorage.getItem("token")
      );

      navigate(`/settlement-details/${gameId}`);
      onClose();
    } catch (error) {
      console.error("Error ending game:", error.message);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg shadow-lg w-full max-w-md">
        <div className="p-4">
          <div className="flex justify-between items-center mb-4">
            <div className="p-3 rounded-lg border border-[#E4E7EC] bg-white shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)]">
              <Power size={24} />
            </div>
            <button
              onClick={onClose}
              className="text-gray-400 hover:text-gray-600 transition-colors p-2"
              aria-label="Close modal"
            >
              <X size={24} />
            </button>
          </div>

          <h2 className="text-xl font-bold mb-2">End game</h2>

          {mismatchAmount ? (
            <p className="text-gray-600 mb-4">
              There is a mismatch of{" "}
              <b>
                {mismatchAmount < 0 && "-"}
                {" "}
                {currencySymbol}{formatAmount(Math.abs(mismatchAmount))}
              </b>
              . Do you still want to end the game?{" "}
            </p>
          ) : (
            <p className="text-gray-600 mb-4">
              Are you sure you want to end the game?
            </p>
          )}
          <div className={mismatchAmount ? "grid grid-cols-2 gap-4" : "w-full"}>
            {mismatchAmount !== 0 && (
              <Button onClick={onClose} variant="outline">
                Go Back
              </Button>
            )}
            <Button onClick={handleEndGame} className="w-full" variant="danger">
              End Game
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(EndGameModal);
