import React, { useState } from "react";
import { X, Trash2 } from "lucide-react";
import axios from "axios";
import { API } from "../../services";
import Button from "../../components/Button/Button";

const DeleteModal = ({
  isOpen,
  onClose,
  gameId,
  playerId,
  playerName,
  updateGameDetails,
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  if (!isOpen) return null;

  const handleDelete = async () => {
    try {
      setLoading(true);

      await API.deletePlayer(
        gameId,
        playerId,
        localStorage.getItem("token")
      );

      updateGameDetails((prevGameData) => {
        const updatedPlayers = prevGameData.players.filter(
          (player) => player.playerId !== playerId
        );

        const totalBuyIn = updatedPlayers.reduce(
          (sum, player) => sum + player.buyIn,
          0
        );
        const playerCount = updatedPlayers.length;
        const avgBuyIn =
          playerCount > 0 ? Math.round(totalBuyIn / playerCount) : 0;

        return {
          ...prevGameData,
          players: updatedPlayers,
          stats: {
            ...prevGameData.stats,
            playerCount,
            totalBuyIn,
            avgBuyIn,
          },
        };
      });

      onClose();
    } catch (error) {
      setError(error?.response?.data?.message || "Failed to remove player");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg shadow-lg w-full max-w-md">
        <div className="p-4">
          <div className="flex justify-between items-center mb-4">
            <div className="p-3 rounded-lg border border-[#E4E7EC] bg-white shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)]">
              <Trash2 size={24} />
            </div>
            <button
              onClick={onClose}
              className="text-gray-400 hover:text-gray-600 transition-colors p-2"
            >
              <X size={24} />
            </button>
          </div>

          <h2 className="text-xl font-bold mb-2">
            Remove {playerName} from the game
          </h2>
          <p className="text-gray-600 mb-6">
            Are you sure you want to remove this player?
          </p>

          {error && <p className="text-red-500 text-sm mb-4">{error}</p>}

          <Button
            variant="dangerFilled"
            onClick={handleDelete}
            className="w-full"
            disabled={loading}
          >
            {loading ? "Removing..." : "Remove"}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default React.memo(DeleteModal);
