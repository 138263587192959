import React, { useEffect, useState } from "react";
import {
  ChevronRight,
  ChevronLeft,
  Mail,
  Edit2,
  LogOut,
  KeyRound,
  HelpCircle,
  Info,
  Share2,
  Gamepad2,
  DollarSign,
} from "lucide-react";
import { useNavigate } from "react-router-dom";
import Input from "../../components/Input/Input";
import LogoutModal from "./LogoutModal";
import PasswordChangeModal from "./PasswordChangeModal";
import { API } from "../../services";
import { useSnackbar } from "../../context/SnackbarContext";
import ShareAppModal from "./ShareAppModal";
import FeedbackModal from "./FeedbackModal";
import Loader from "../../components/Loader/Loader"; // Add this import

const Profile = () => {
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();

  const [userData, setUserData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [defaultCurrency, setDefaultCurrency] = useState("INR");
  const [showLogout, setShowLogout] = useState(false);
  const [showPasswordChange, setShowPasswordChange] = useState(false);
  const [showShare, setShowShare] = useState(false);
  const [showFeedback, setShowFeedback] = useState(false);

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const token = localStorage.getItem("token");
        const {
          data: { data },
        } = await API.getUserProfile(token);
        setUserData(data);
        setDefaultCurrency(data?.currency || "INR");
      } catch (error) {
        console.error("Error fetching user profile:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserProfile();
  }, []);

  if (isLoading) {
    return <Loader context="profile" />;
  }

  const SettingsOption = ({
    icon: Icon,
    title,
    subtitle,
    rightElement,
    onClick,
    textColor,
  }) => (
    <div
      className="flex items-center justify-between p-4 bg-white border-b border-gray-100 rounded-md cursor-pointer"
      onClick={onClick}
    >
      <div className="flex items-center space-x-4">
        <div className={`p-2 rounded-xl`}>
          <Icon className={`w-6 h-6 ${textColor || "black"}`} />
        </div>
        <div>
          <div className={`font-medium text-base ${textColor || ""}`}>
            {title}
          </div>
          {subtitle && <div className="text-gray-400 text-sm">{subtitle}</div>}
        </div>
      </div>
      {rightElement || (
        <div className="flex items-center">
          {rightElement}
          <ChevronRight
            onClick={onClick}
            className={`w-5 h-5 ${textColor ? textColor : "text-gray-400"}`}
          />
        </div>
      )}
    </div>
  );

  const handleBack = () => {
    navigate(-1);
  };

  const currencyOptions = [
    {
      label: (
        <div className="flex gap-1 items-center align-middle">&#8377; INR</div>
      ),
      value: "INR",
    },
  ];

  return (
    <div className="max-w-3xl mx-auto p-4 pt-0 flex flex-col gap-2 h-[calc(100%-5rem)] overflow-auto">
      <header className="flex items-center mb-4 pt-4 sticky top-0 z-20 bg-white">
        <button onClick={handleBack} className="py-2">
          <ChevronLeft size={24} />
        </button>
        <h1 className="text-md font-bold w-full text-center">Profile</h1>
      </header>

      <div className="flex justify-between items-start mb-4 border rounded-lg p-4  shadow-md hover:shadow-lg transition-shadow duration-200">
        <div className="flex items-center space-x-4">
          {/* <div className="w-14 h-14 rounded-full bg-gray-200 overflow-hidden">
            <img
              src="https://avatar.iran.liara.run/public/boy?username=Prachi"
              alt="Profile"
              className="w-full h-full object-cover"
            />
          </div> */}
          <div>
            <h2 className="text-lg font-bold leading-[1]">{userData?.name}</h2>
            <p className="text-sm text-gray-600">@{userData?.username}</p>
            <div className="flex items-center text-gray-600 mt-2">
              <Mail className="w-4 h-4 mr-1" />
              <span className="text-sm">{userData?.email}</span>
            </div>
          </div>
        </div>
        {/* <Edit2
          className="w-4 h-4 text-gray-500 cursor-pointer"
          onClick={() => navigate("edit-profile")}
        /> */}
      </div>
      <div className="bg-white mb-3 border rounded-lg  shadow-md hover:shadow-lg transition-shadow duration-200">
        <SettingsOption
          icon={Gamepad2}
          title="Set Game Defaults"
          onClick={()=>navigate("/profile/game-defaults")}
          iconBgColor="bg-gray-500"
        />
        <SettingsOption
          icon={DollarSign}
          title="Set Default Currency"
          rightElement={
            <div>
              <Input
                type="select"
                name="currency"
                options={currencyOptions}
                placeholder="Select currency"
                value={defaultCurrency}
                onChange={(e) => setDefaultCurrency(e.target.value)}
              />
            </div>
          }
          iconBgColor="bg-blue-500"
        />
      </div>
      {/* Help Section */}
      <div className="bg-white mb-3 border rounded-lg  shadow-md hover:shadow-lg transition-shadow duration-200">
        <SettingsOption
          icon={HelpCircle}
          title="Feedback"
          onClick={() => setShowFeedback(true)}
        />
        <SettingsOption
          icon={Share2}
          title="Share app"
          subtitle=""
          onClick={() => setShowShare(true)}
        />
      </div>

      {/* Account Section */}
      <div className="bg-white mb-3 border rounded-lg  shadow-md hover:shadow-lg transition-shadow duration-200">
        <SettingsOption
          icon={KeyRound}
          title="Change Password"
          onClick={() =>
            userData?.google_id
              ? showSnackbar(
                  "Password cannot be set/changed for account managed by google",
                  "info"
                )
              : setShowPasswordChange(true)
          }
        />
        <SettingsOption
          icon={LogOut}
          title="Logout"
          onClick={() => setShowLogout(true)}
          textColor="text-[#EE203D]"
        />
      </div>
      <LogoutModal isOpen={showLogout} onClose={() => setShowLogout(false)} />
      <PasswordChangeModal
        isOpen={showPasswordChange}
        onClose={() => setShowPasswordChange(false)}
      />
      <ShareAppModal isOpen={showShare} onClose={() => setShowShare(false)} />
      <FeedbackModal
        isOpen={showFeedback}
        onClose={() => setShowFeedback(false)}
      />
    </div>
  );
};

export default Profile;
