export const buttonStyles = {
  base: `
      flex justify-center items-center gap-2
      font-semibold rounded transition-all duration-200 ease-in-out
      disabled:cursor-not-allowed disabled:pointer-events-none disabled:opacity-80
    `,
  fullWidth: "w-full",
  variants: {
    primary: `
        px-[1.125rem] py-[0.625rem] rounded
        border border-[#191D23]
        bg-[#191D23] hover:bg-[#2C3038] active:bg-[#0F1114]
        text-white
        shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)]
        hover:shadow-[0px_2px_4px_0px_rgba(16,24,40,0.1)]
        active:shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)]
        text-base leading-6
        disabled:bg-[#191D23]/70 disabled:border-[#191D23]/70 disabled:shadow-none
      `,
    google: `
        px-[1.125rem] py-[10px]
        bg-white hover:bg-[#F9FAFB] active:bg-[#F3F4F6]
        text-[#191D23]
        border border-[#D0D5DD]
        shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)]
        hover:shadow-[0px_2px_4px_0px_rgba(16,24,40,0.1)]
        active:shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)]
        text-sm leading-[140%] tracking-[-0.14px]
        disabled:bg-[#F9FAFB] disabled:border-[#D0D5DD]/70 disabled:text-[#191D23]/70 disabled:shadow-none
      `,
    outline: `
        px-[11px] py-[11px] rounded
        border border-[#292F33]
        text-[#222] hover:text-[#191D23] active:text-[#0F1114]
        hover:bg-[#F9FAFB] active:bg-[#F3F4F6]
        text-xs leading-normal tracking-[-0.48px] uppercase
        disabled:border-[#292F33]/70 disabled:text-[#222]/70
      `,
    danger: `
        px-[11px] py-[11px] rounded
        border border-[#EE203D]
        bg-none hover:bg-[#fbf4f5] active:bg-[#fff2f4]
        text-[#EE203D] hover:text-[#e61a37] active:text-[#df1431]
        text-xs leading-normal tracking-[-0.48px] uppercase
        disabled:border-[#EE203D]/70 disabled:text-[#EE203D]/70
      `,
    dangerFilled: `
        px-[11px] py-[11px] rounded
        border border-[#EE203D]
        text-[#fff] hover:text-[#fbf4f5] active:text-[#fff2f4]
        bg-[#EE203D] hover:bg-[#e61a37] active:bg-[#df1431]
        text-xs leading-normal tracking-[-0.48px] uppercase
        disabled:bg-[#EE203D]/70 disabled:border-[#EE203D]/70 disabled:text-white/70
      `,
    outlineWithIcon: `
        px-[1.125rem] py-[0.625rem] rounded
        border border-[#191D23]
        bg-white hover:bg-[#F9FAFB] active:bg-[#F3F4F6]
        text-[#344054]
        shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)]
        hover:shadow-[0px_2px_4px_0px_rgba(16,24,40,0.1)]
        active:shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)]
        text-base leading-6
        disabled:border-[#191D23]/70 disabled:text-[#344054]/70 disabled:bg-white disabled:shadow-none
      `,
    link: `
        px-1 py-0.5
        text-[#3B82F6] hover:text-[#2563EB]
        text-sm font-semibold
        hover:underline
        bg-transparent
        disabled:text-[#3B82F6]/70 disabled:no-underline
      `,
    icon: `
        w-10 h-10 p-2 rounded-full
        bg-white hover:bg-[#F9FAFB] active:bg-[#F3F4F6]
        disabled:bg-[#F9FAFB] disabled:text-[#191D23]/70
      `,
    iconRoundPrimary: `
        w-10 h-10 p-2 rounded-full
        border border-[#191D23]
        bg-[#191D23] hover:bg-[#2C3038] active:bg-[#0F1114]
        text-white
        shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)]
        hover:shadow-[0px_2px_4px_0px_rgba(16,24,40,0.1)]
        active:shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)]
        text-base leading-6
        disabled:bg-[#191D23]/70 disabled:border-[#191D23]/70 disabled:text-white/70 disabled:shadow-none
      `,
    iconRound: `
        w-10 h-10 p-2 rounded-full
        bg-white hover:bg-[#F9FAFB] active:bg-[#F3F4F6]
        border border-[#D0D5DD]
        shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)]
        hover:shadow-[0px_2px_4px_0px_rgba(16,24,40,0.1)]
        active:shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)]
        disabled:bg-[#F9FAFB] disabled:border-[#D0D5DD]/70 disabled:text-[#191D23]/70 disabled:shadow-none
      `,
    iconRoundOutline: `
        w-10 h-10 p-2 rounded-full
        bg-gray-100 hover:bg-[#F9FAFB] active:bg-[#F3F4F6]
        border border-[#D0D5DD]
        shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)]
        hover:shadow-[0px_2px_4px_0px_rgba(16,24,40,0.1)]
        active:shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)]
        disabled:bg-[#F9FAFB] disabled:border-[#D0D5DD]/70 disabled:text-[#191D23]/70 disabled:shadow-none
      `,
  },
};