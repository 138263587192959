import { Trash2, X } from "lucide-react";
import Button from "../../components/Button/Button";

const DeleteRoomModal = ({
  isOpen,
  onClose,
  onConfirm,
  isDeleting,
  roomName,
}) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50" onClick={onClose}>
      <div className="bg-white rounded-lg shadow-lg w-full max-w-md z-51">
        <div className="p-4">
          <div className="flex justify-between items-center mb-4">
            <div className="bg-gray-100 p-2 rounded-lg">
              <Trash2 className="text-gray-600" size={24} />
            </div>
            <button
              onClick={onClose}
              className="text-gray-400 hover:text-gray-600 transition-colors p-2"
              aria-label="Close modal"
            >
              <X size={24} />
            </button>
          </div>

          <h2 className="text-xl font-bold mb-2">
            Delete Room {roomName || ""}
          </h2>
          <p className="text-gray-600 mb-4">
            Are you sure you want to delete this room? This action cannot be
            undone. All games and data associated with this room will be
            permanently deleted.
          </p>
          <Button
            variant="dangerFilled"
            fullWidth
            onClick={onConfirm}
            disabled={isDeleting}
          >
            {isDeleting ? "Deleting..." : "Delete"}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DeleteRoomModal;
